import React, {useContext, useRef, useEffect, useState} from 'react'

// import {Helmet} from 'react-helmet'
import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'
import Nav from './Nav'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


import Footer from './Footer'
import AOS from "aos"
import "aos/dist/aos.css"

const OrderService=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [flightService,setFlightService]= useState(false)
  const [generalService,setGeneralService]= useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [destination,setDestination] = useState("")
  const [address,setAddress] = useState("")
  const [serviceType,setserviceType] = useState("Land Survey")
  const [startDate, setStartDate] = useState(new Date());
  const[url,setUrl]= useState()

  const[progress,setProgress]= useState(false)


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();




useEffect(  ()=>{
  AOS.init();
  AOS.refresh();

},[])



const serviceBook=()=>{


    
    fetch("/addService",{
      method:"post",
      headers:{
          "Content-Type":"application/json",

      },
      body: JSON.stringify({
        
       
        name, phone, email,date:startDate ,address,description,serviceType
        
      })
      
  }).then(res=>res.json())
  .then(data=> {

    // toast.success("Order Placed Successfully")      

     
      if(data.error){
        toast.error(data.error)      
      }
      else{

        toast.success("Order Placed Successfully")      
         history('/orderservice')
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }






  
  




return(


<main class="bg-gray-50 dark:bg-gray-800 h-screen  relative ">
<div class="flex items-start justify-between">
   
    <div class="flex flex-col w-full ">

        
        <Nav/>


      
      



<div 
// data-aos="zoom-in" 
class=" bg-white pt-4 pb-6">

<h1 class="text-center font-bold text-2xl sm:text-4xl text-black leading-tight mt-4  ">Order Our Services</h1>

<img 

// data-aos="flip-left" 
// data-aos-duration="800" 

src="/ul.png" class="mx-auto"></img>



</div>


<div 
//  data-aos="fade-up"
 class="flex flex-wrap w-full bg-white overflow-auto justify-center ">
<div class="flex flex-col w-full md:w-1/2">
   
    <div class="grid h-1/2 place-items-center    ">

   
{/* <form class="flex w-full max-w-sm space-x-3">
    <div class="w-full max-w-2xl px-5 py-10 m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="mb-6 text-3xl font-light text-center text-gray-800 dark:text-white">
        </div>
        <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">
            <div class="col-span-2 lg:col-span-1">
                <div class=" relative ">
                    <input type="text" id="contact-form-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"/>
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1">
                    <div class=" relative ">
                        <input type="text" id="contact-form-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="email"/>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <label class="text-gray-700" for="name">
                            <textarea class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Enter your comment" name="comment" rows="5" cols="40">
                            </textarea>
                        </label>
                    </div>
                    <div class="col-span-2 text-right">
                        <button type="submit" class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Order Now
                        </button>
                    </div>
                </div>
            </div>
        </form> */}


<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-10 m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
       
    <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-blue-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-blue-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
          </div>

          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-blue-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div>
       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Address</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-blue-300  focus:outline-none" 
          
            placeholder="Address"
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">

          <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-blue-300  focus:outline-none" 
          

    
            value={serviceType}
            onChange={(e)=>setserviceType(e.target.value)}
  >
    <option>Select Services</option>
    
    
    <option>Land Survey</option>
   
  </select>
           
          </div>
          <div class="col-span-2">
                        <label class="text-gray-700">
                            <textarea class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"  placeholder="Enter your Details"  rows="3" cols="40"
                              value={description}
                              onChange={(e)=>setDescription(e.target.value)}
                            >
                            </textarea>
                        </label>
                    </div>
         
          <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-blue-300  focus:outline-none" selected={startDate} onChange={date => setStartDate(date)} />

              </div>

              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-blue-500 hover:bg-blue-400 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        
                        onClick={()=>serviceBook()}
                        >
                            Order Now
                        </button>

                        <ToastContainer/>

                    </div>
               
                
                </div>
        </div> 
         
 

            </div>
        </div>
        <div class="md:w-1/4 w-full">
        <img  src='/logofc.png' class=" md:mt-14 bg-white text-gray-700  " />

  
  
         </div>
    </div>
















        {/* <Helmet>
    <title>Services</title>
    <meta name="description" content="Flight Bookings, Electrician, Plumber, Construction" />
</Helmet>
        */}


       




















    </div>
</div>



<Footer/>






</main>


);


}


export default OrderService