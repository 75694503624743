import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";



import SideNav from './SideNav'

const CarDashboard=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [sort,setSort]= useState(false)


  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[rateEdit,setRateEdit]= useState()
    


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();


  useEffect(  ()=>{



    fetch('/viewcar',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.realestates)
  })
  
  

  },[])


  const postStockUpdate = (id)=>{

    fetch('/updatecarStatus',{
  
      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")
  
      },
      body: JSON.stringify({
      id:id
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
  
      else{
         history('/cars')

    //    window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }
  

  const deleteNetflixSuscription = (postId)=>{

    fetch(`/deletecar`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        },
        body: JSON.stringify({
            id:postId
            })

    }).then(res=>res.json())
    .then(result=>{
        history('/cars')

    }).catch(err=>{
        console.log(err)
    })
}



const fetchPost=(query)=>{


  setSearch(query)

  fetch('/search-cardashboard',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      query
    })

  }).then(res=> res.json())
    .then(results=>{
      
      setData(results.realestate)
    })


}







return(


<main class="relative h-screen overflow-hidden bg-gray-100 dark:bg-gray-800 rounded-2xl">
    <div class="flex items-start justify-between">
       


      <SideNav page="cars"/>


        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
            <header class="z-40 items-center w-full h-16 bg-white shadow-lg dark:bg-gray-700 rounded-2xl">
                <div class="relative z-20 flex flex-col justify-center h-full px-3 mx-auto flex-center">
                    <div class="relative flex items-center w-full pl-1 lg:max-w-68 sm:pr-2 sm:ml-0">
                        <div class="container relative left-0 z-50 flex w-3/4 h-auto h-full">
                            <div class="relative flex items-center w-full h-full lg:w-64 group">
                                <div class="absolute z-50 flex items-center justify-center block w-auto h-10 p-3 pr-2 text-sm text-gray-500 uppercase cursor-pointer sm:hidden">
                                    <svg fill="none" class="relative w-5 h-5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                                        <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z">
                                        </path>
                                    </svg>
                                </div>
                                <svg class="absolute left-0 z-20 hidden w-4 h-4 ml-4 text-gray-500 pointer-events-none fill-current group-hover:text-gray-400 sm:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z">
                                    </path>
                                </svg>
                                <input type="text" class="block w-full py-1.5 pl-10 pr-4 leading-normal rounded-2xl focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-100 dark:bg-gray-800 text-gray-400 aa-input" placeholder="Search"/>
                                    <div class="absolute right-0 hidden h-auto px-2 py-1 mr-2 text-xs text-gray-400 border border-gray-300 rounded-2xl md:block">
                                        +
                                    </div>
                                </div>
                            </div>
                            <div class="relative flex items-center justify-end w-1/4 p-1 ml-5 mr-4 sm:mr-0 sm:right-auto">
                                <a href="#" class="relative block">
                                    <img alt="profil" src="/images/person/1.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                 

                <h1 class="text-center p-4" >Not Verified</h1>
 

 <div class="grid grid-cols-1 md:grid-cols-4 gap-4 ml-4">
         
         
         
         
         

         {data ? data.map(items=>{

             return(

              <>
              {items.status=="Not Verified"? 
              
                                             
<div class="shadow-lg rounded-2xl  bg-white ">
  <div class=" ">
    
  <Carousel autoPlay showThumbs={false}>

    {items.pic.map((pics,index)=>{

      return(
        <div>
       <img src={pics} alt="adidas" class="w-80 h-80" />

        </div>
      )

    })}

    </Carousel>
      
      
  </div>
<div class="bg-white  p-4 rounded-lg">
<p class="text-gray-600 text-xl font-bold ">
  {items.realestateType}
</p>
<div class="flex items-center justify-between ">
  <p class="text-gray-400">
      {items.description}
  </p>
  
</div>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.address}</span>        </p>


<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
        <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


          <a  href={`tel:${items.phone}`}>

<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



 <a href={`mailto:${items.email}`}>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     




 <h6>Status: {items.status}</h6>

 <button class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 px-4  rounded"
                        
                        onClick={()=>{if(window.confirm('Are you sure it is Delivered?')) postStockUpdate(items._id)}}
                        >
                          Verified
                            </button>


<div class="flex flex-row">





<div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
        
        onClick={()=>{if(window.confirm('Are you sure its VERIFIED?')) deleteNetflixSuscription(items._id)}}
  
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
  
        ></path>  
        </svg></div>
 

</div>



</div>
</div>:null

}
      
              
              
             
                  </>
              
              

                  
                  
                  
                  )


         }):null }

         
              
                     
                         </div>
   <h1 class="text-center p-4" >Verified</h1>


 <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ml-4">
         
         
         
         
         

         {data ? data.map(items=>{

             return(

              <>



              {items.status=="Verified"?
              
              
              <>
              
              
                                             
<div class="shadow-lg rounded-2xl  bg-white ">
  <div class=" ">
    
  <Carousel autoPlay showThumbs={false}>

    {items.pic.map((pics,index)=>{

      return(
        <div>
       <img src={pics} alt="adidas" class="h-56" />

        </div>
      )

    })}

    </Carousel>
      
      
  </div>
<div class="bg-white m-2  rounded-lg">
<p class="text-gray-600 text-xl font-bold ">
  {items.realestateType}
</p>
<div class="flex items-center justify-between ">
  <p class="text-gray-400">
      {items.description}
  </p>
  
</div>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.address}</span>        </p>


<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
        <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


          <a  href={`tel:${items.phone}`}>

<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



 <a href={`mailto:${items.email}`}>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     




 <h6>Status: {items.status}</h6>

<div class="flex flex-row">





<div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
        
        onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteNetflixSuscription(items._id)}}
  
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
  
        ></path>  
        </svg></div>
 

</div>



</div>
</div>


      
              
              
              {/* <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 cursor-pointer" 
         
                  >
                     
                      <div class="flex flex-col justify-start">
                      <p class="text-gray-800 dark:text-gray-200 text-xl  mt-4 text-center">

                              <a  className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
  
  <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 font-medium">{items.name}</span>
</a>
                              <a href={`tel:${items.phone}`} className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
  
  
  </span>
 <span className="text-sm text-gray-500 font-medium">{items.phone}</span>
</a>
                              <a href={`mailto:${items.email}`} className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"> 
 <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
 
 </span>
 <span className="text-sm text-gray-500 font-medium">{items.email}</span>
</a>
                              <a  className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 font-medium">{items.address}</span>
</a>


                              <h6>Status: {items.status}</h6>



                              
                              </p>
                        

                      </div>
                  </div> 
                  
                   */}
                  </>
              
              
              
              :null}


              
              
        
                    
          

                  </>  )


         }):null }

         
              
                     
                         </div>
         
      


                 
                </div>
            </div>
        </div>
    </main>



);


}


export default CarDashboard