import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'
import Nav from './Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SellRealestate=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [flightService,setFlightService]= useState(false)
  const [generalService,setGeneralService]= useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [map,setMap] = useState("")
  const [address,setAddress] = useState("")
  const [serviceType,setserviceType] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const[url,setUrl]= useState([])
  const[progress,setProgress]= useState(false)


  console.log("PICS",url)

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();



   const serviceBook=()=>{

    fetch("/addrealestate",{
      method:"post",
      headers:{
          "Content-Type":"application/json",

      },
      body: JSON.stringify({
        
       
        name, phone, email,date:startDate ,address,description,map,realestateType:serviceType, pic:url
        
      })
      
  }).then(res=>res.json())
  .then(data=> {

    console.log(data.service)
     
      if(data.error){
        toast.error(data.error)      
      }
      else{

        toast.success("Delivered Successfully")      

        // console.log(data.result)
        
           history(`/buyrealestate`)
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }

   const uploadimg=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          console.log(data.url)

          setUrl(oldArray => [...oldArray, data.url])

         // setUrl(item=>[...item, {data.url}])


          console.log(url)
          setProgress(false)


       
     })
     .catch(err=>{
         console.log(err)
     })

}




 
  // const images = [
  //   { url: "/logo.png" },
  //   { url: "/Aer.png" },
  //   { url: "http://www.drukings.com/wp-content/uploads/2021/07/Aeroplane-08-1.png" },
    
  // ];

  
  



return(

  <>
   <Nav/>
<div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 py-5">
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden" style={{maxWidth: 1000}}>
      <div className="md:flex w-full">
        <div className="hidden md:block w-1/2 bg-gray-50 py-10 px-10">

            <img  src='./logo.png'></img>
        </div>
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Sell</h1>
          </div>
          <div>
          <div className="flex -mx-3">
              
            </div>
            

          
          <span className=" text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>


          <span className="text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>


          <span className="text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          <span className="text-sm text-gray-600">Address</span>
            {/* <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Address"
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >

            </input> */}
        
            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >
            <option>Select Location</option>


            <option>Bumthang</option>
            <option>Chhukha</option>
            <option>Dagana</option>
            <option>Gelephu</option>
            <option>Gasa</option>
            <option>Haa</option>
            <option>Lhuntse</option>
            <option>Mongar</option>
            <option>Paro</option>
            <option>Pemagatshel</option>
            <option>Punakha</option>
            <option>Samdrup Jongkhar</option>
            <option>Samtse</option>
            <option>Sarpang</option>
            <option>Thimphu</option>
            <option>Trashigang</option>
            <option>Trashiyangtse</option>
            <option>Trongsa</option>
            <option>Tsirang</option>
            <option>Wangdue Phodrang</option>
            <option>Zhemgang</option>
           
            </select>
            

            <div className="py-1">
            <span className="text-sm text-gray-600">Property Type</span>

            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



              value={serviceType}
              onChange={(e)=>setserviceType(e.target.value)}
            >
            <option>Select Type </option>


            <option>Land</option>
            <option>Building</option>
            <option value="Flat">Flat for Sale</option>
            <option value="Rent">Flat for Rent</option>
            </select>
            
            </div>

                        
          <span className="text-sm text-gray-600">Map Link</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder='Enter location in map'
            value={map}
            onChange={(e)=>setMap(e.target.value)}
            >

            </input>
          <span className="text-sm text-gray-600">Description</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            >

            </input>

            <div className="col-span-6 sm:col-span-4">
            <label className="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">   </span>
                {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadimg}
                />
                
            </label>
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
        
        <div class="grid grid-cols-4 grid-row-2 grid-flow-row auto-cols-min ">

    { url.length>0? url.map((pics,index)=>{

      return(
        <img src={pics} alt="img" 

        class= "p-1 w-24 h-24" />
      )

    }):<></> }
       
       
  </div>


        {/* {url.length>0? url.map(item=>{
          return(
            <img className=" pt-4 pb-2  h-24 w-24 flex items-center " src={item} ></img>
          )
        }) :null
        } */}
            
        
        
        </div>
                        
           
            
            <div class="m-2.5">
                                <span class="block w-full rounded-md shadow-sm">
                                    <button type="button" class="py-2 px-4  bg-orange-300 hover:bg-orange-400 focus:bg-orange-400 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                  onClick={()=>{serviceBook()}}

                                    >
                                        Post
                                    </button>
                                    
                                </span>
                            </div>

        
              
    </div>
</div>

        
          </div>
        </div>
      </div>

 

            <ToastContainer/>






 </>

);


}


export default SellRealestate