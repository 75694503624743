import React,{ useState, useContext ,createContext, useReducer,useEffect} from 'react';
// import SignupPage from './pages/Signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import ProtectedRoutesUser from './utils/ProtectedRoutesUser';


import {reducer,initialState} from "./reducers/userReducer"
import Login from './pages/Login'
import Home from './pages/Home'
import BuyRealestate from './pages/BuyRealestate'
import SellRealestate from './pages/SellRealestate';
import OrderService from './pages/OrderService';
import Dashboard from './pages/Dashboard';
import RealestateDashboard from './pages/RealestateDashboard';
import About from './pages/About';
import Contact from './pages/Contact';
import BuyCars from './pages/BuyCars';
import CarDashboard from './pages/CarsDashboard';
import SellCar from './pages/SellCar';
import Services from './pages/Services';
import RealestateOrders from './pages/RealestateOrders';
import CarOrders from './pages/CarOrders';
import SingleRealestate from './pages/SingleRealestate';
import SingleCar from './pages/SingleCar';
import SingleCategory from './pages/SingleCategory';
export const UserContex = createContext()


function App() {
  const [state, dispatch] = useReducer(reducer,initialState)


  
   const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
//   useEffect(() => {
//     // Example: Emit a message to the server
//     socket.emit('chat message', 'Hello, server!');

//     // Example: Listen for messages from the server
//     socket.on('chat message', (msg) => {
//         console.log('Message from server: ' + msg);
//     });

//     // Clean up on component unmount
//     return () => {
//         socket.disconnect();
//     };
// }, []);






  return (
    <>
        <UserContex.Provider value={{state,dispatch}}>

      <Router>
        {/* {isLoggedIn ? (
        <button onClick={logOut}>Logout</button>
        ) : (
        <button onClick={logIn}>Login</button>
        )} */}
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/buyrealestate" element={<BuyRealestate/>}/>
          <Route path="/buycars" element={<BuyCars/>}/>
          <Route path="/singlerealestate/:productid" element={<SingleRealestate/>}/>
          <Route path="/singlecar/:productid" element={<SingleCar/>}/>
          <Route path="/singlecategory/:category" element={<SingleCategory/>}/>
          <Route path="/sellrealestate" element={<SellRealestate/>}/>
          <Route path="/sellcars" element={<SellCar/>}/>
          <Route path="/orderservice" element={<OrderService/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contactus" element={<Contact/>}/>
          
          {/* <Route path="/profile" element={<Profile/>}/> */}
          {/* <Route path="/category" element={<EventCategory/>}/> */}
          
          {/* <Route path="*" element={<Error/>}/> */}

          <Route  element={<ProtectedRoutes/>}>
          <Route path="/realestatedashboard" element={<RealestateDashboard/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/cars" element={<CarDashboard/>}/>
          <Route path="/carorders" element={<CarOrders/>}/>
          <Route path="/survey" element={<Services/>}/>
          <Route path="/realestateorders" element={<RealestateOrders/>}/>
          
          </Route>


          <Route  element={<ProtectedRoutesUser/>}>
          <Route path="/profile" element={<Login/>}/>
         
          </Route>
         
         
        </Routes>
      </Router>
      </UserContex.Provider>

    </>
  );
}

export default App;
