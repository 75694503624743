import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'

import Nav from './Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from './Footer'
import MiniSearch from 'minisearch';



const BuyCars=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")


  const [generalService,setGeneralService]= useState(false)

  const [name, setName] = useState("")
  const [selectedProduct, setSelectedProduct] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address,setAddress] = useState("")
  const [message,setMessage] = useState("")

  const [show,setShow] = useState(false)

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();


  useEffect(  ()=>{



    fetch('/viewcar',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.realestates)
  })
  
  

  },[])



  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    // Create a MiniSearch instance
    const miniSearch = new MiniSearch({
      fields: ['realestateType', 'description','address'], // fields to index for full-text search
      storeFields: ['_id', 'realestateType', 'description','address','pic'], // fields to return with search results
      searchOptions: {
        boost: { realestateType: 3, description: 2, address:2 },
        prefix: true,
        fuzzy: 0.5,
      },
      idField:"_id"
    });

    // Index the products data
    miniSearch.addAll(data);

    // Perform the search
    if (searchQuery) {
      const searchResults = miniSearch.search(searchQuery);
      setResults(searchResults);
      // setData(searchResults)
    } else {
      setResults([]);
    }
  }, [searchQuery]);




  const bookrealestate = ()=>{

    fetch('/bookcar',{
  
      method:"post",
      headers:{
        "Content-Type":"application/json",
  
      },
      body: JSON.stringify({
      name,
      email,
      phone,
      address,
      message,
      product:selectedProduct
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        toast.error("Unsuccessful Query")

        console.log("Error")
      }
  
      else{
          toast.success("Sent Successfully")
        //  history.go('/')

    //    window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }
  

  const fetchPost=(query)=>{


    setSearch(query)
  
    fetch('/search-car',{
  
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
  
        "Content-Type":"application/json"
  
      },
      body:JSON.stringify({
        query
      })
  
    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.realestate)
      })
  
  
  }




  

return(



    <>
    
    <main class="bg-gray-50 dark:bg-gray-800 h-screen overflow-auto relative ">
    
    
    <ToastContainer/>
{/*                 
    <div className="w-full  mt-6 h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none  items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div> */}
        <div class="flex items-start justify-between">
       
       <div class="flex flex-col w-full ">

           
           <Nav/>


           

           <div class=" h-50 sm:h-96  w-full bg-white  bg-cover md:bg-contain bg-center bg-no-repeat" style={{ 
     backgroundImage: `url("/carbanner.png")` 
   }}>

<div class=" mx-8 sm:mx-20 my-24 sm:my-32 ">

<div className="w-full bg-gray-300 border-none rounded-lg h-16 bg-opacity-80 hover:bg-opacity-100 p-4  focus:outline-none focus:border-gray-100 active:outline-none flex focus:outline-none justify-between items-center relative">
<input type="text" name="search" id="search" placeholder={  'Search'} 
className=" w-full bg-gray-300 border-none rounded-lg h-16 bg-opacity-10 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"

// value= {search} onChange={(e)=>fetchPost(e.target.value)}
value={searchQuery}
onChange={(e) => setSearchQuery(e.target.value)}
/>
<button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
viewBox="0 0 24 24" className="w-6 h-6 text-purple-500">
<path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
</svg>
</button>


</div>
<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">
    {results.map((result) => {
            return(
          
            <li class="flex flex-row">
                             <a href={`/singlecar/${result._id}`} class="relative block">
   <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
                <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                        <img alt="profil" src={result.pic[0]} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                </div>
                <div class="flex-1 pl-1 mr-16">
                    <div class="font-medium dark:text-white">
                    {result.name} {result.description}
                    </div>
                    <div class="text-sm text-gray-600 dark:text-gray-200">
                        {result.address}
                    </div>
                </div>
                <div class="text-xs text-gray-600 dark:text-gray-200">
                    {result.realestateType}
                </div>
            </div>                    </a>

        </li>
            )
        }
        )}
      
      
       
    </ul>
</div>




</div>


      
           



           </div>




<ToastContainer/>
         






           {/* <Helmet>
       <title>Services</title>
       <meta name="description" content="Flight Bookings, Electrician, Plumber, Construction" />
   </Helmet>
           */}


              
        







<a href="/sellcars">

{/* <button type="button" class="fixed z-90 bottom-10 right-8 bg-green-600 w-12 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-green-700 hover:drop-shadow-2xl hover:animate-bounce duration-300">
    <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
        </path>
    </svg>
    Upload
</button> */}


<button class="fixed  z-50 bottom-10 md:top-96 right-8 bg-blue-500 w-24 md:w-32 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-600 hover:drop-shadow-2xl hover:animate-bounce duration-300"> 
<svg  width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
        </path>
    </svg><h6 class="ml-2 text-xs md:text-sm md:p-2">Sell Car</h6>

</button>

</a>
















       </div>
   </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 my-4 p-4">
                   
                   
                   
                   
                   

                   {data ? data.map(items=>{

                       return(

                        <>


                        { items.status=="Verified"?
                        
                  
                                        
          <div class="shadow-lg rounded-2xl  bg-gray-50 ">
            <div class=" "
              onClick={ () =>{
                history(`/singlecar/${items._id}`);
              }  }
            
            >
              
            <Carousel autoPlay showThumbs={false}>

              {items.pic.map((pics,index)=>{

                return(
                  <div>
                 <img src={pics} alt="adidas" class="w-80 h-64" />

                  </div>
                )

              })}

              </Carousel>
                
                
            </div>
    <div class="bg-white  p-4 rounded-lg">
        <p class="text-gray-600 text-xl font-bold ">
            {items.realestateType}
        </p>
         <div class="flex items-center justify-between ">
            <p class="text-gray-400">
                {items.description}
            </p>
            
        </div>
        <p class=" flex flex-row text-gray-50 text-xs">
        <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
          <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
           <span className="text-sm text-gray-500 ">{items.address}</span>        </p>
        
        
       
       
       <div class="flex flex-row">


      

      
    
              <a href='tel:+97517739380'>

            
            <button type="button" class="flex mt-2 flex justify-center items-center  bg-green-500 hover:bg-green-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-20 h-10 transition ease-in duration-200 text-center text-base font-semibold shadow-md   rounded-lg ">
    <svg class="h-8 w-8 text-white"  width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
</button>
  </a>
            <button type="button" class="flex ml-2 mt-2 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-20 h-10 transition ease-in duration-200 text-center text-base font-semibold shadow-md   rounded-lg "
            
            onClick={()=>{setGeneralService(true); setSelectedProduct(items._id)}}
            >
            <svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"/>
</svg>

</button>

 </div>



    </div>
</div>
:null

            }

                            </>  )


                   }):null }

                   
                        
                               
                                   </div>


                                   {
generalService? 
(
  <>
    <div
      className="justify-cente rounded-lg w-auto border-rounded shadow  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative border-rounded  rounded-lg  my-6 mx-auto max-w-3xl">
        {/*content*/}
       


          <form class="flex w-full max-w-sm space-x-3 content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="mb-6 text-3xl font-light  text-center text-gray-800 dark:text-white">
            Post Query
        </div>
       
       
       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Address</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Address"
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >

            </input>
          </div>

          <div class="col-span-2">
                        <label class="text-gray-700">
                            <textarea class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"  placeholder="Enter your Details"  rows="3" cols="40"
                              value={message}
                              onChange={(e)=>setMessage(e.target.value)}
                            >
                            </textarea>
                        </label>
                    </div>
         
       
        
              <br/>
                
               
                
                </div>
        </form> 
         

        <div className="flex items-center  justify-end p-6 rounded-b">
            <button
              className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease"  }}
              onClick={() =>setGeneralService(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={()=>{setGeneralService(false);bookrealestate()}}
              >
              Post 
            </button>

            
          </div>





          
          
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)

      :null

}
             
                   
                   



                    

</main>


    <Footer/>
    </>
                   
   

);


}


export default BuyCars




                
{/* 
                   {data ? data.map(items=>{

                       return(

                        <>



                      <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 cursor-pointer" 
                   
                            >
                               
                                <div class="flex flex-col justify-start">
                                <p class="text-gray-800 dark:text-gray-200 text-xl  mt-4 text-center">

                                        <a  className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
           <span className="text-sm text-gray-500 font-medium">{items.name}</span>
         </a>
                                        <a href={`tel:${items.phone}`} className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg></span>
           <span className="text-sm text-gray-500 font-medium">{items.phone}</span>
         </a>
                                        <a href={`mailto:${items.email}`} className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"> <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg></span>
           <span className="text-sm text-gray-500 font-medium">{items.email}</span>
         </a>
                                        <a  className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
           <span className="text-sm text-gray-500 font-medium">{items.address}</span>
         </a>


                                        <h6>Status: {items.status}</h6>



                                        
                                        </p>
                                   {items.status!="Confirmed"?
                                  <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded"
                                  
                                  onClick={()=>{if(window.confirm('Are you sure it is Delivered?')) postStockUpdate(items._id)}}
                                  >
                                    Confirm
                                      </button>:<></>
                                  }
  
                                </div>
                            </div>


                        
                        
                  
                              
                    

                            </>  )


                   }):null } */}

                   
                    
