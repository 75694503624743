import React from "react";


const footer =()=>{


    return(

        <footer class="px-3 py-8  bg-gray-300 dark:bg-gray-800 text-2 text-gray-500 dark:text-gray-200 transition-colors duration-200">
<div class="flex flex-col">
    <div class="md:hidden mt-7 mx-auto w-11 h-px rounded-full">
    </div>
    <div class="mt-4 md:mt-0 flex flex-col md:flex-row">
        
    <img  src='/logo.png' class=" pl-8  w-24 h-24 items-center justify-center " />

        
        <nav class="flex-1 flex flex-col items-center justify-center md:items-end md:border-r border-gray-100 md:pr-5">
            <a aria-current="page" href="/about" class="hover:text-gray-700 dark:hover:text-white">
                About Us
            </a>
            <a aria-current="page" href="/contactus" class="hover:text-gray-700 dark:hover:text-white">
                Contact Us
            </a>
           
        </nav>
        <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full">
        </div>
        <div class="mt-4 md:mt-0 flex-1 flex items-center justify-center md:border-r border-gray-100">
           
            <a class="ml-4 hover:text-primary-gray-20" href="https://www.facebook.com/drukings">
               
                <svg class="h-8 w-8 text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" /></svg>
            </a>
            <a class="ml-4 hover:text-primary-gray-20" href="https://www.instagram.com/drukings/">
            <svg class="h-8 w-8 text-xl hover:text-gray-800 dark:hover:text-white transition-colors duration-200"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
            </a>
        </div>
        <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full ">
        </div>
        <div class="mt-7 md:mt-0 flex-1 flex flex-col items-center justify-center md:items-start md:pl-5">
            <span class="">
                © {new Date().getFullYear()}
            </span>
            <span class="mt-7 md:mt-1">
                Drukings.com
                
            </span>
        </div>
    </div>
</div>
</footer>
    )

}



export default footer