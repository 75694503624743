import React, {useContext, useRef, useEffect, useState} from 'react'

// import {Helmet} from 'react-helmet'
import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'
// import Nav from './Nav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos"
import "aos/dist/aos.css"
import Nav from './Nav';

import MiniSearch from 'minisearch';

// Sample data


const Home=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [flightService,setFlightService]= useState(false)
  const [generalService,setGeneralService]= useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [destination,setDestination] = useState("")
  const [address,setAddress] = useState("")
  const [location,setLocation] = useState([])  
  const [serviceType,setserviceType] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const[url,setUrl]= useState()

  const[progress,setProgress]= useState(false)


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();




useEffect(  ()=>{
  AOS.init();
  AOS.refresh();

},[])


useEffect(  ()=>{



    fetch('/viewrealestate',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.realestates)
      setLocation(getAllVenues(result.realestates))

  })
  
  

  },[])



  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    // Create a MiniSearch instance
    const miniSearch = new MiniSearch({
      fields: ['realestateType', 'description','address'], // fields to index for full-text search
      storeFields: ['_id', 'realestateType', 'description','address','pic'], // fields to return with search results
      searchOptions: {
        boost: { realestateType: 3, description: 2, address:2 },
        prefix: true,
        fuzzy: 0.5,
      },
      idField:"_id"
    });

    // Index the products data
    miniSearch.addAll(data);

    // Perform the search
    if (searchQuery) {
      const searchResults = miniSearch.search(searchQuery);
      setResults(searchResults);
    } else {
      setResults([]);
    }
  }, [searchQuery]);



const bookFlight=()=>{

 if(url){  fetch("/addFlight",{
      method:"post",
      headers:{
          "Content-Type":"application/json",

      },
      body: JSON.stringify({
        
       
         name,
         email,
         phone,
         destination,
         date:startDate,
         url
        
      })
      
  }).then(res=>res.json())
  .then(data=> {

    console.log(data.flight)
     
      if(data.error){
        toast.error(data.error)      
      }
      else{

        toast.success("Delivered Successfully")      

        // console.log(data.result)
        
          //  history.push(`/home`)
      }
  })
  .catch(err=>{
      console.log(err)
  })
}

  else{

    toast.error("File Not Uploaded")

  }



   }


   const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-realestate',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query,
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.realestate)
      })


  }


   const uploadimg=({target:{files}})=>{

   setProgress(true)
    let data = new FormData()

    for(const file of files){

   data.append('pdf', file)
   data.append('name',file.name)
}
       fetch("/pdf", {
        'Content-Type': 'multipart/form-data',
         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{
       

          setUrl(data.path)
          setProgress(false)


       
     })
     .catch(err=>{
         console.log(err)
     })

}




 
  // const images = [
  //   { url: "/logo.png" },
  //   { url: "/Aer.png" },
  //   { url: "http://www.drukings.com/wp-content/uploads/2021/07/Aeroplane-08-1.png" },
    
  // ];



  const getAllVenues = (events) => {
    // Use a Set to store unique venues
    const uniqueVenues = new Set();
  
    // Iterate through the events and add each venue to the Set
    events.forEach((event) => {
      uniqueVenues.add(event.address);
    });
  
    // Convert the Set back to an array
    return Array.from(uniqueVenues);
  };
  
  




return(




<main class="bg-gray-50  h-screen  relative ">
<div class="flex items-start justify-between">
   
    <div class="flex flex-col w-full ">

    <Nav/>


        {/* <div data-aos="fade-right" class="  w-full bg-white       bg-cover md:item-center bg-center bg-no-repeat" style={{ 
  backgroundImage: `url("newban.png")` 
}}> */}
        <div data-aos="fade-right" class="  w-full bg-white     bg-cover   md:item-center bg-center bg-no-repeat" style={{ 
//   backgroundImage: `url("https://gogordian.com/landings/fotos/91_foto_1.jpg")` 
  backgroundImage: `url("bg.jpg")` 
}}>


    {/* <img src="/bg.png"></img> */}

            <div class=" mx-8 sm:mx-20 my-24 sm:my-40 ">

        <div className="w-full  object-center  h-10 p-3  items-center relative">

        
        
</div>







        </div>
        </div>

        <div  className=" inset-x-0  top-1/2 transform -translate-y-1/2 flex items-center justify-center ">
    <div data-aos="fade-up"     data-aos-duration="1000" className="md:w-3/5 w-full">
      
<div class="relative w-full overflow-y-auto bg-white shadow-xl  rounded-t-lg">
      <div class="flex flex-cols  place-items-center px-4 py-4">
      <a href="/singlecategory/Land" >

<span class="px-4 py-2 mr-2 flex items-center text-base rounded-full text-orange-500 border border-orange-500 undefined hover:bg-orange-500 hover:text-white ">
<svg class="h-6 w-6  mr-1"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="18" y2="6.01" />  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="15" x2="15" y2="20" /></svg>
  Land
</span>
</a>
<a href="/singlecategory/Flat" >

<span class="px-4 py-2 mr-2  flex items-center text-base rounded-full text-orange-500 border border-orange-500 undefined hover:bg-orange-500 hover:text-white ">
<svg class="h-6 w-6 mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="3" y1="21" x2="21" y2="21" />  <line x1="9" y1="8" x2="10" y2="8" />  <line x1="9" y1="12" x2="10" y2="12" />  <line x1="9" y1="16" x2="10" y2="16" />  <line x1="14" y1="8" x2="15" y2="8" />  <line x1="14" y1="12" x2="15" y2="12" />  <line x1="14" y1="16" x2="15" y2="16" />  <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" /></svg>
  Flat
</span>
</a>
<a href="/singlecategory/Building" >

<span class="px-4 py-2 mr-2  flex items-center text-base rounded-full text-orange-500 border border-orange-500 undefined hover:bg-orange-500 hover:text-white ">
<svg class="h-6 w-6  mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M3 21h7v-3a2 2 0 0 1 4 0v3h7" />  <line x1="6" y1="21" x2="6" y2="12" />  <line x1="18" y1="21" x2="18" y2="12" />  <path d="M6 12h12a3 3 0 0 0 3 -3a9 8 0 0 1 -9 -6a9 8 0 0 1 -9 6a3 3 0 0 0 3 3" /></svg>
  Building
</span>
</a>


{/* <a href="/singlecategory/Rent" >

         
<span class="px-4 py-2 mr-2  flex items-center text-base rounded-full text-orange-500 border border-orange-500 undefined hover:bg-orange-500 hover:text-white ">
<svg class="h-6 w-6  mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />  <line x1="13" y1="7" x2="13" y2="7.01" />  <line x1="17" y1="7" x2="17" y2="7.01" />  <line x1="17" y1="11" x2="17" y2="11.01" />  <line x1="17" y1="15" x2="17" y2="15.01" /></svg>
  Rent
</span>
</a> */}
{/* <a href="/buycars" >

<span class="px-4 py-2 mr-2  flex items-center text-base rounded-full text-blue-500 border border-blue-500 undefined hover:bg-blue-500 hover:text-white ">
<svg class="h-6 w-6 mr-1 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" /></svg>
  Car
</span>
</a>
<a href="/orderservice" >

<span class="px-4 py-2 mr-2  flex items-center text-base rounded-full text-blue-500 border border-blue-500 undefined hover:bg-blue-500 hover:text-white ">
<svg class="h-6 w-6  mr-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="23 7 16 12 23 17 23 7" />  <rect x="1" y="5" width="15" height="14" rx="2" ry="2" /></svg>
  Survey
</span>
</a> */}


          
      </div>
     
</div>

    <div className="w-full bg-white shadow-xl  border-none rounded-b-lg h-16 bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-white active:outline-none flex  focus:outline-none justify-between items-center relative">





<input type="text" name="search" id="search" placeholder={  'Search'} 
    className=" 
    rounded-lg border-lg flex-1 mb-6 appearance-none border border-gray-200 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparen
    w-full bg-white border-lg ring-orange-500 rounded-lg h-16 bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    />
<button type="submit" className="ml-1 mb-6 outline-none border-none focus:border-none  active:outline-none  ">

<svg class="h-8 w-8 text-orange-500  transition-transform duration-300 hover:rotate-12"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="11" cy="11" r="8" />  <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
</button>

     
</div>

<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow-lg ">
    <ul class="flex flex-col divide-y divide">
    {results.map((result) => {
            return(
          
            <li class="flex flex-row">
                             <a href={`/singlerealestate/${result._id}`} class="relative block">
   <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
                <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                        <img alt="profil" src={result.pic[0]} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                </div>
                <div class="flex-1 pl-1 mr-16">
                    <div class="font-medium ">
                    {result.name} {result.description}
                    </div>
                    <div class="text-sm text-gray-600 ">
                        {result.address}
                    </div>
                </div>
                <div class="text-xs text-gray-600 ">
                    {result.realestateType}
                </div>
            </div>                    </a>

        </li>
            )
        }
        )}
      
      
       
    </ul>
</div>

    </div>
    
  </div>


<ToastContainer/>
      

{/* 

<div data-aos="fade-up"     data-aos-duration="1000"
 class=" bg-gray-50  pb-6">

<h1 class="text-center font-bold text-2xl sm:text-4xl text-black leading-tight mt-4  ">Our Sevices</h1>





<div class="flex  justify-center items-center text-center ">




<div class=" rounded-2xl w-64 p-4 mb-4 py-6 bg-none items-center mx-auto justify-center">

<a href='/orderservice'>




<div class="flex flex-col items-center justify-center">

    <img class="md:w-24 md:h-24 w-16 h-16   relative" src='/survey.png'>
    </img>
    <p class="text-gray-800 md:text-xl text-lg font-medium mb-4 ">
    Survey 

    </p>
  
</div>
</a>
</div>

<div class=" rounded-2xl w-64 mb-4 py-6 bg-none items-center mx-auto justify-center">

<a href='/buycars'>



<div class="flex flex-col items-center justify-center">
<img class="md:w-24 md:h-24 w-16 h-16   relative" src='/car.png'>
    </img>
    <p class="text-gray-800 md:text-xl text-lg font-medium mb-4 ">
        Cars
    </p>
   
</div> 
 </a>
</div>


<div class=" rounded-2xl w-64 p-4 mb-4 py-6 bg-none items-center mx-auto justify-center">

<a href='/buyrealestate'>


<div class="flex flex-col items-center justify-center">

<img class="md:w-24 md:h-24 w-16 h-16  relative" src='/house.png'>
    </img>
 
    <p class="text-gray-800 md:text-xl text-lg font-medium mb-4 ">
        Realestate 
    </p>
 
</div> </a>
</div>





</div>


</div> */}






<div data-aos="fade-up"     data-aos-duration="1200" class="md:px-6 md:py-6 m-6  md:rounded-lg md:bg-gray-50 md:shadow-lg">
<div class="flex md:flex-row flex-col md:items-end md:justify-between mb-12 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Explore our Properties
            </p>
            <p class="text-xl font-light text-gray-400">
                All listed properties are verified by our experts 
            </p>
        </div>
        <div class="md:text-end mt-2">
            <div class="flex   max-w-sm space-y-3 h-16  md:space-x-3 md:space-y-0">
               
<select class="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-80 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="animals"

    value= {search} onChange={(e)=>fetchPost(e.target.value)}
    // value={searchQuery}
    // onChange={(e) => setSearchQuery(e.target.value)}
>
    <option value="">
        Search Location
    </option>
  
    {location.length>0? location.map(item=>{
return(  <option value={item}>
  {item}
</option>)
        }):<></>}
</select>

                </div>
            </div>
        </div>

<div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

{data.filter(item => item.status == 'Verified'||item.status=="Sold").slice(0, 8).map(item=>{
return(



<div class="relative overflow-hidden rounded-xl  shadow-lg cursor-pointer h-90  w-80  ">
{item.status=="Sold"?
                         <img src="/sold.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            :<></>}
        <a href={`/singlerealestate/${item._id}`} class="block w-full h-full">
        {/* <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/> */}
            
        <Carousel autoPlay showThumbs={false}>

{item.pic.map((pics,index)=>{

  return(
    <div>
   <img src={pics} alt="adidas" class="w-80 h-52" />

    </div>
  )

})}

</Carousel>
            <div class="w-full p-4 bg-white ">
                <p class="font-medium text-orange-500 text-md">
                    {item.realestateType}
                </p>
                <div class="flex items-center">
    <svg class="h-6 w-6 text-orange-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
    
     <p class="text-md text-black ">
    
    {item.address}
</p>
</div>





            
  
          
  

                <p class="font-light text-gray-400  text-md">
                    {item.description.substring(0,28)}... Read More
                </p>
               
            </div>
        </a>
    </div>
  


)

})}





</div>
 <div class="mt-6  flex justify-center items-center">
<button onClick={()=>history('/buyrealestate')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-orange-300  bg-none hover:bg-orange-500 focus:ring-red-500 focus:ring-offset-red-200 text-orange-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-orange-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">

Find more Listings
<svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
</button>
</div>

 </div>


 <div id="about" data-aos="fade-up" data-aos-easing=" linear"
     data-aos-duration="500" class="bg-white flex md:flex-row flex-col ml-4 mr-4 mt-2 md:space-x-3 ">

 
        
        <div class="text-start md:w-1/2 mb-1 py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 shadow-lg rounded-2xl bg-fit relative backdrop-brightness-50 overflow-hidden bg-gray-50	"
         style={{ 
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("https://cdn.hswstatic.com/gif/buy-vacant-lot-update.jpg") `,
            backgroundRepeat:"no-repeat",
            backgroundSize:"cover"
          }}
        >
        <h2 class="text-3xl font-extrabold text-white text-2xl">
            <span class="block">
               Buy Realestate
            </span>
            <span class="block text-orange-400">
            Buy verified realestate properties with expert conultation.
            </span>
        </h2>
        <ul class="mt-10">
                <li>
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-orange-500 rounded-md">
                                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6  text-white">
                                Buy Lands
                            </h5>
                            {/* <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-700">
                                Know everything about your business in a single glance with your new dashboard.
                            </p> */}
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-orange-500 rounded-md">
                                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-white">
                                Buy Buildings 
                            </h5>
                            {/* <p class="mt-2 text-base leading-6 text-gray-700 dark:text-gray-300">
                                Construction materials such as Doors, Door Frames, Windows, Window Frame and much more.
                            </p> */}
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-orange-500 rounded-md">
                                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-white">
                                Buy Flats 
                            </h5>
                            {/* <p class="mt-2 text-base leading-6 text-gray-700 dark:text-gray-300">
                                Bulk Order Tender Items.
                            </p> */}
                        </div>
                    </div>
                </li>
            </ul>
      



             <div class="w-1/2 px-4 pb-4 ml-auto text-gray-500 md:w-1/3 absolute  right-0 bottom-0 " >
            <div class=" mt-12  rounded-md shadow">
                <a href="/buyrealestate" class="w-24 h-24 ">

                {/* <svg class="text-orange-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" /></svg> */}

                <svg class="absolute w-24 h-24 rounded-full  -bottom-6 -right-6 md:-right-5 text-orange-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>
                </a>
                
            </div>
        </div>
       
    </div>  



        <div class=" md:w-1/2 py-12 mb-1  px-4 px-6 lg:py-16 lg:px-8  z-20 shadow-lg rounded-2xl bg-fit bg-no-repeat relative overflow-hidden"
         style={{ 
            background: ` linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("https://arbtech.co.uk/wp-content/uploads/2021/10/land-for-sale-rural-landscape-970x628.jpg")`,
            backgroundRepeat:"no-repeat",
            backgroundSize:"cover"
          }}
        >
        <h2 class="text-3xl font-extrabold text-white dark:text-white text-2xl">
            <span class="block">
                Sell Realestate
            </span>
            <span class="block text-orange-400">
            Sell your realestate properties at Drukings hassle-free.
            </span>
        </h2>
        <p class="text-xl mt-4 text-gray-200 ">
        Sell your real estate properties hassle-free with Drukings. Our platform allows you to quickly list your property with just a few clicks, reaching thousands of potential buyers across Bhutan. With secure and trusted transactions, you can have peace of mind knowing your sales process is safe. Drukings also offers 24/7 support to assist you every step of the way, ensuring a smooth and user-friendly experience. Easily manage your listings and connect with verified buyers for fast and reliable sales. Let Drukings simplify your real estate journey today!             </p>
        <div class="w-1/2 px-4 pb-4 ml-auto text-gray-500 md:w-1/3 absolute  right-0 bottom-0 ">
            <div class="mt-2  shadow">
                <a href="/sellrealestate" class="bg-green-400">

                <svg class="absolute w-24 h-24 rounded-full  -bottom-6 -right-6 md:-right-5 text-orange-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>

                   {/* <button type="button" class="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 py-4 px-6  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-full ">
                </button>  */}
                </a>
                
            </div>
        </div>
    </div>  
</div>






 <div data-aos="fade-up"     data-aos-duration="1200" class="bg-white  overflow-hidden relative lg:flex lg:items-center">
    <div class="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black  sm:text-4xl">
            <span class="block">
            Empowering Your Real Estate Journey            </span>
        </h2>
        <p class="text-md mt-4 text-gray-400">
        At Drukings, we are dedicated to transforming the real estate landscape in Bhutan through our innovative online marketplace. Our platform prioritizes security and transparency, empowering users to navigate land transactions with confidence. By embodying our core values of integrity, customer focus, and sustainability, we strive to create a trusted environment where buyers and sellers can connect seamlessly and responsibly.        </p>
      
    </div>
    <div class="flex items-center gap-8 p-8 lg:p-24">

    <div class="p-4 bg-white shadow-lg rounded-2xl  ">
    <div class="flex items-center">
        <span class="relative   text-green-500 rounded-full">
        <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="1" />  <circle cx="12" cy="12" r="5" />  <circle cx="12" cy="12" r="9" /></svg>
        </span>
        <p class="ml-2 text-gray-700 font-bold text-lg ">
            Mission
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="my-4  text-left text-gray-800 ">
        To provide a secure and transparent online platform for seamless land transactions in Bhutan.


        </p>
        {/* <div class="relative h-2 bg-gray-200 rounded w-28">
            <div class="absolute top-0 left-0 w-2/3 h-2 bg-green-500 rounded">
            </div>
        </div> */}
    </div>
</div>
        <div>

        <div class="p-4 bg-white shadow-lg rounded-2xl  ">
    <div class="flex items-center">
        <span class="relative   text-green-500 rounded-full">
        <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />  <polyline points="4 12 7 8 11 10 16 4 20 8" /></svg>
        </span>
        <p class="ml-2 text-gray-700 font-bold text-lg ">
            Vision
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="my-4  font-bold text-left text-gray-800 ">
        To be Bhutan's most trusted and innovative real estate marketplace.


        </p>
        {/* <div class="relative h-2 bg-gray-200 rounded w-28">
            <div class="absolute top-0 left-0 w-2/3 h-2 bg-green-500 rounded">
            </div>
        </div> */}
    </div>
</div>

<div class="p-4 bg-white shadow-lg rounded-2xl   mt-2">
    <div class="flex items-center">
        <span class="relative  rounded-full">
        <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 12l2 2l4 -4" />  <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" /></svg>
        </span>
        <p class="ml-2 text-gray-700 text-lg  font-bold ">
            Values
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="my-4 text-left text-gray-800 ">
        We value transparency, innovation, customer focus, and sustainability at Drukings.


        </p>
        {/* <div class="relative h-2 bg-gray-200 rounded ">
            <div class="absolute top-0 left-0 w-full h-2 bg-green-500 rounded">
            </div>
        </div> */}
    </div>
</div>
        </div>
    </div>
</div>







   


<div id="about" data-aos="fade-up" data-aos-duration="800" class="p-6 m-6 bg-gray-50  flex flex-col md:flex-row overflow-hidden shadow-lg rounded-2xl "
>


    <div class="text-center md:w-full  py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 class="text-3xl font-extrabold text-gray-800  text-2xl">
            <span class="block">
                Who Are We?
            </span>
            {/* <span class="block text-indigo-500">
            Crafting excellence with every cut: your one-stop woodworking solution
            </span> */}
        </h2>
        <p class="text-xl  mt-4 text-gray-600">
        Drukings is Bhutan’s leading online platform specializing in the sale and purchase of land. Our mission is to empower individuals, families, and businesses by offering a secure, intuitive marketplace that facilitates seamless transactions between buyers and sellers. Committed to enhancing transparency, efficiency, and innovation within Bhutan’s real estate industry, we provide a trusted space for property transactions, backed by deep local expertise and cutting-edge technology. At Drukings, we aim to redefine the real estate experience by prioritizing user confidence, secure operations, and ease of use at every step of the process.        
          </p>
        {/* <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
                <a href="tel:+97577428435">

                   <button type="button" class="py-4 px-6  bg-orange-500 hover:bg-orange-700 focus:ring-orange-500 focus:ring-offset-orange-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Contact Us
                </button> 
                </a>
                
            </div>
        </div> */}
    </div>

    {/* <img src="about.png" class="  h-80 w-1/3 md:ml-14 mt-12"/> */}
    {/* <img src="/left.png" alt="btc logo" class="absolute  h-full    -right-24 md:-right-6"/>
    <img src="/right.png" alt="btc logo" class="absolute w-46 h-full    -left-24 md:-left-6"/> */}

</div>



<div data-aos="fade-up"     data-aos-duration="1200">



<p  class="text-3xl font-extrabold text-black  sm:text-4xl text-center m-8">
            Why Drukings?
        </p>
     

        <div class="flex-wrap items-center justify-center gap-8 text-center sm:flex">
    <div class="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-orange-500 rounded-md">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
        Customer-Centric Approach
        </h3>
        <p class="py-4 text-gray-500 text-md ">
        Our clients are at the heart of everything we do. We listen to your needs and work tirelessly to exceed your expectations.        </p>
    </div>
    <div class="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 sm:mt-16 md:mt-20 lg:mt-24 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-orange-500 rounded-md">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
        Integrity and Transparency
        </h3>
        <p class="py-4 text-gray-500 text-md ">
        We believe in building lasting relationships based on trust. You can count on us for honest advice and transparent dealings.        </p>
    </div>
    <div class="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-orange-500 rounded-md">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
        Comprehensive Services        </h3>
        <p class="py-4 text-gray-500 text-md ">
        From initial consultation to finalizing the deal, we offer end-to-end real estate solutions to simplify your property journey.        </p>
    </div>
</div>

      
    </div>







    {/* <p  class="text-3xl font-extrabold text-black  sm:text-4xl text-center mt-12">
    Testimonials
        </p>
     

    <div class="flex flex-col items-center w-full gap-4 p-8 mb-8 md:flex-row md:mb-0 flex-between">
 
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="/images/person/1.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                    Jean Miguel
                </span>
                <span class="flex items-center text-xs ">
                    User of Tail-Kit
                    <img src="/icons/rocket.svg" class="w-4 h-4 ml-2"/>
                </span>
            </div>
        </div>
    </div>
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="/images/person/1.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                    Jean Miguel
                </span>
                <span class="flex items-center text-xs ">
                    User of Tail-Kit
                    <img src="/icons/rocket.svg" class="w-4 h-4 ml-2"/>
                </span>
            </div>
        </div>
    </div>
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="/images/person/1.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                    Jean Miguel
                </span>
                <span class="flex items-center text-xs ">
                    User of Tail-Kit
                    <img src="/icons/rocket.svg" class="w-4 h-4 ml-2"/>
                </span>
            </div>
        </div>
    </div>
</div>
 */}














        {/* <Helmet>
    <title>Services</title>
    <meta name="description" content="Flight Bookings, Electrician, Plumber, Construction" />
</Helmet>
        */}


       




















    </div>
</div>

<Footer/>




</main>

);


}


export default Home