import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    // <div>
    //   <nav className="bg-gray-900">
    //     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    //       <div className="flex items-center justify-between h-20">
    //         <div className="flex items-center">
    //           <div className="flex-shrink-0">
    //            <Link to='/'> 
    //            <img
    //               className="h-16 w-16"
    //               src="/logo.png"
    //               alt="Workflow"
    //             />
    //            </Link>
               
    //           </div>
    //           <div className="hidden md:block">
    //             <div className="ml-10 flex items-baseline space-x-4">
    //               <a
    //                 href="/"
    //                 className=" text-gray-50 px-3 py-2 rounded-md text-sm font-medium"
    //               >
    //                 Home
    //               </a>

                  

    //               {/*  */}
    //               <a
    //                 href="/orderservice"
    //                 className="text-gray-50 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //               >
    //                 Services
    //               </a>
    //               <a
    //                 href="/buycars"
    //                 className="text-gray-50 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //               >
    //                 Cars
    //               </a>
    //               <a
    //                 href="/buyrealestate"
    //                 className="text-gray-50 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //               >
    //                 Realestate
    //               </a>

    //               {/* <a
    //                 href="#"
    //                 className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //               >
    //                 Calendar
    //               </a> */}

    //               {/* <a
    //                 href="#"
    //                 className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //               >
    //                 Reports
    //               </a> */}
    //             </div>
    //           </div>
    //         </div>
    //         <div className="-mr-2 flex md:hidden">
    //           <button
    //             onClick={() => setIsOpen(!isOpen)}
    //             type="button"
    //             className="bg-none inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white  "
    //             aria-controls="mobile-menu"
    //             aria-expanded="false"
    //           >
    //             <span className="sr-only">Open main menu</span>
    //             {!isOpen ? (
    //               <svg
    //                 className="block h-6 w-6"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke="currentColor"
    //                 aria-hidden="true"
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   strokeWidth="2"
    //                   d="M4 6h16M4 12h16M4 18h16"
    //                 />
    //               </svg>
    //             ) : (
    //               <svg
    //                 className="block h-6 w-6"
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke="currentColor"
    //                 aria-hidden="true"
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   strokeWidth="2"
    //                   d="M6 18L18 6M6 6l12 12"
    //                 />
    //               </svg>
    //             )}
    //           </button>
    //         </div>
    //       </div>
    //     </div>

    //     <Transition
    //       show={isOpen}
    //       enter="transition ease-out duration-100 transform"
    //       enterFrom="opacity-0 scale-95"
    //       enterTo="opacity-100 scale-100"
    //       leave="transition ease-in duration-75 transform"
    //       leaveFrom="opacity-100 scale-100"
    //       leaveTo="opacity-0 scale-95"
    //     >
    //       {(ref) => (
    //         <div className="md:hidden" id="mobile-menu">
    //           <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
    //             <a
    //               href="/"
    //               className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
    //             >
    //               Home
    //             </a>

    //             <a
    //               href="/bookflight"
    //               className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
    //             >
    //               Flights
    //             </a>

    //             <a
    //               href="/orderservice"
    //               className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
    //             >
    //               Services
    //             </a>
    //             <a
    //               href="/buyrealestate"
    //               className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
    //             >
    //               Realestate
    //             </a>
             

              
    //           </div>
    //         </div>
    //       )}
    //     </Transition>
    //   </nav>

     
    // </div>
    <nav className="sticky z-50 top-0 left-0 w-full  bg-white shadow-md transition-transform duration-300 ease-in-out">
    <div className="flex flex-wrap items-center justify-between p-4 bg-white">
    <div className="w-auto lg:order-2 lg:w-1/5 lg:text-center">
        <a className="text-xl font-semibold text-gray-800 font-heading flex items-center" href="/">
          <img src="/headerlogo.jpeg" className="h-10 " alt="Logo" />
          {/* Drukings */}
        </a>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-3 py-2 text-orange-500 border border-orange-500 rounded"
        >
          {/* <svg className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
            <path
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"}
            />
          </svg> */}
            {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
        </button>
      </div>
      <div className="hidden w-full lg:flex lg:order-1 lg:w-2/5">
        <Link to="/" className="block mt-4 mr-10 text-blue-900 lg:mt-0 hover:text-indigo-600">
          Home
        </Link>
        <Link to="/about" className="block mt-4 mr-10 text-blue-900 lg:mt-0 hover:text-indigo-600">
          About
        </Link>
        <Link to="/contactus" className="block mt-4 mr-10 text-blue-900 lg:mt-0 hover:text-indigo-600">
          Contact
        </Link>
        
      
      
      </div>


      <div class="hidden w-full navbar-menu lg:order-3 lg:block lg:w-2/5 lg:text-right">
   
 
       
        <a class="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600" href="/buyrealestate">
          Buy 
      </a>
     
        <a class="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600" href="/sellrealestate">
          Sell 
      </a>
     

  
  </div>
    
    </div>

    {/* Mobile Menu */}
    <Transition
      show={isOpen}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="lg:hidden">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
            Home
          </Link>
          {/* <Link to="/about" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
            About Us
          </Link> */}
          <Link to="/buyrealestate" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
            Buy 
          </Link>
         
          <Link to="/sellrealestate" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
            Sell 
          </Link>
         
        </div>
      </div>
    </Transition>
  </nav>
  );
}

export default Nav;