import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'

import Nav from './Nav'
import SideNav from './SideNav'

const Services=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [sort,setSort]= useState(false)


  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[rateEdit,setRateEdit]= useState()
    


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();


  useEffect(  ()=>{



    fetch('/viewServices',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.services)
  })
  
  

  },[])


  const postStockUpdate = (id)=>{

    fetch('/updateServiceStatus',{
  
      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")
  
      },
      body: JSON.stringify({
      id:id
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
  
      else{
        history('/survey')
      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-service',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.service)
      })


  }

  
  const deleteService = (postId)=>{

    fetch(`/deleteService`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        },
        body: JSON.stringify({
            id:postId
            })

    }).then(res=>res.json())
    .then(result=>{
        history('/survey')

    }).catch(err=>{
        console.log(err)
    })
}



return(

    <div>



<div  className="flex h-screen bg-white">

<div className=" "></div>


<SideNav/>



        <div className="flex-1 flex flex-col overflow-hidden ">

          
           <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">


             
                   

    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
                  
                   
              
             <h1 class="text-center p-4" >Survey Orders</h1>
 

           <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
                   
                   
                   
                   

                   {data ? data.map(items=>{

                       return(

                        <>



                      <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 cursor-pointer" 
                   
                            >
                                
                                <div class="flex flex-col justify-start">
                                <p class="text-gray-800 dark:text-gray-200 text-xl  mt-4 text-center">

                                        <a  className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
           <span className="text-sm text-gray-500 font-medium">{items.name}</span>
         </a>
                                        <a href={`tel:${items.phone}`} className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg></span>
           <span className="text-sm text-gray-500 font-medium">{items.phone}</span>
         </a>
                                        <a href={`mailto:${items.email}`} className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"> <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg></span>
           <span className="text-sm text-gray-500 font-medium">{items.email}</span>
         </a>
                                        <a  className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
           <span className="text-sm text-gray-500 font-medium">{items.address}</span>
         </a>


                                        <h6>Status: {items.status}</h6>



                                        
                                        </p>
                                   {items.status!="Delivered"?
                                  <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded"
                                  
                                  onClick={()=>{if(window.confirm('Are you sure it is Delivered?')) postStockUpdate(items._id)}}
                                  >
                                    Delivered
                                      </button>:<></>
                                  }
  



<div style={{cursor:"pointer"}} className=" text-red-600 "
                  
                  onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteService(items._id)}}
            
                  >
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
                  ></path>  
                  </svg></div>
                                </div>
                            </div>


                        
                        
                  
                              
                    

                            </>  )


                   }):null }

                   
                         
                
                   
                   
                  
                   
                   
                   
                   
                   
                        
                   
                                       
                                      
                                      
                                      
                                      
                   
                   
                   
                   
                                   
                   
                       
                                      
                               
                                   </div>
                   
                   
              
             
                   
              
</main>
</div>

   </div>
 </div>  


);


}


export default Services