import { Outlet, Navigate } from 'react-router-dom'


const ProtectedRoutes = () => {

    const user = JSON.parse( localStorage.getItem("admin"))




    return(
        user ? <Outlet/> : <Navigate to="/login"/>);
}

export default ProtectedRoutes