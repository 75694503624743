import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'


const SideNav=(props)=>{
  const navigate = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();



const Logout = ()=>{
    localStorage.clear("user")
    navigate("/")
}

return(


    <>
{/*     
    <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
 <div className=" resize flex flex-row">
   <div className="flex flex-col  top-0 left-0 w-32 bg-white h-full border-r  bg-gray-50  overflow-hidden">
     <div className="flex  ">
       <img class="w-24 h-24" src={logo}/>
       
     </div>
     <ul className="flex flex-col ">
       <li>
         <a href="/dashboard" className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg></span>
           <span className="text-sm text-gray-500 font-medium">Reports</span>
         </a>
       </li>
       <li>
         <a href="/flight" className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800">
         <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2-4l-2 -4h3l2 2h4l-2 -7h3z" /></svg> </span>
           <span className="text-sm text-gray-500 font-medium">Flight</span>
         </a>
       </li>
       <li>
         <a href="/services" className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800">
         <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path></svg></span>
           <span className="text-sm text-gray-500 font-medium">Services</span>
         </a>
       </li>
     
       <li>
         <a href="/realestatedashboard" className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800">
         <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
         <svg class="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"/>
</svg>
          </span>
           <span className="text-sm text-gray-500 font-medium">Realestate</span>
         </a>
       </li>
       <li>
         <a href="/hiredb" className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800">
         <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">

         <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />  <line x1="3" y1="9" x2="7" y2="9" /></svg>
         </span>
           <span className="text-sm text-gray-500 font-medium">Machine Hire</span>
         </a>
       </li>
     
    
     
      
       <li>
         <a href="#" className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800">
           <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg></span>
           <span className="text-sm text-gray-500 font-medium"
            onClick={()=>{

             localStorage.clear()
             dispatch({type:"CLEAR"})
             history('/login')
         }}
           
           >Logout</span>
         </a>
       </li>
     </ul>
   </div>
 </div> */}

<div>
    
    <div class="relative hidden h-screen my-4 ml-4 mb-4 shadow-lg lg:block w-52 ">
    <div class="h-full bg-white rounded-2xl ">
        <div class="flex items-center justify-center pt-6">
    <img src='/logo.png' class="h-16"/>
        </div>
        <nav class="mt-6">
            <div>
                <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="dashboard"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  0":""}`}
                
                
                href="/dashboard">
                    <span class="text-left">
                        <svg width="20" height="20" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Dashboard
                    </span>
                </a>
                <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="realestate"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  ":""}`}
                
                href="/realestatedashboard">
                    <span class="text-left">
                        <svg width="20" height="20" fill="currentColor" class="m-auto" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1024 1131q0-64-9-117.5t-29.5-103-60.5-78-97-28.5q-6 4-30 18t-37.5 21.5-35.5 17.5-43 14.5-42 4.5-42-4.5-43-14.5-35.5-17.5-37.5-21.5-30-18q-57 0-97 28.5t-60.5 78-29.5 103-9 117.5 37 106.5 91 42.5h512q54 0 91-42.5t37-106.5zm-157-520q0-94-66.5-160.5t-160.5-66.5-160.5 66.5-66.5 160.5 66.5 160.5 160.5 66.5 160.5-66.5 66.5-160.5zm925 509v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm0-260v-56q0-15-10.5-25.5t-25.5-10.5h-568q-15 0-25.5 10.5t-10.5 25.5v56q0 15 10.5 25.5t25.5 10.5h568q15 0 25.5-10.5t10.5-25.5zm0-252v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm256-320v1216q0 66-47 113t-113 47h-352v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-768v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-352q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1728q66 0 113 47t47 113z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Realestate
                    </span>
                </a>
                
                 {/* <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="cars"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  0":""}`}
                
                href="/cars">
                    <span class="text-left">
                    <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" /></svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Cars
                    </span>
                </a> */}
                {/* <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="survey"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  0":""}`}
                
                href="/Survey">
                    <span class="text-left">
                        <svg width="20" height="20" fill="currentColor" class="m-auto" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M685 483q16 0 27.5-11.5t11.5-27.5-11.5-27.5-27.5-11.5-27 11.5-11 27.5 11 27.5 27 11.5zm422 0q16 0 27-11.5t11-27.5-11-27.5-27-11.5-27.5 11.5-11.5 27.5 11.5 27.5 27.5 11.5zm-812 184q42 0 72 30t30 72v430q0 43-29.5 73t-72.5 30-73-30-30-73v-430q0-42 30-72t73-30zm1060 19v666q0 46-32 78t-77 32h-75v227q0 43-30 73t-73 30-73-30-30-73v-227h-138v227q0 43-30 73t-73 30q-42 0-72-30t-30-73l-1-227h-74q-46 0-78-32t-32-78v-666h918zm-232-405q107 55 171 153.5t64 215.5h-925q0-117 64-215.5t172-153.5l-71-131q-7-13 5-20 13-6 20 6l72 132q95-42 201-42t201 42l72-132q7-12 20-6 12 7 5 20zm477 488v430q0 43-30 73t-73 30q-42 0-72-30t-30-73v-430q0-43 30-72.5t72-29.5q43 0 73 29.5t30 72.5z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Survey
                    </span>
                </a> */}
               
                <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="realestateorder"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  ":""}`}
                href="/realestateorders">                <span class="text-left">
                        <svg width="20" height="20" class="m-auto" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Realestate Orders
                    </span>
                </a>
                {/* <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="realestateorder"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  0":""}`}
                href="">                <span class="text-left">
                        <svg width="20" height="20" class="m-auto" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Realestate Orders
                    </span>
                </a> */}
                <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="log"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  ":""}`}
                href=""
                
                onClick={()=>{Logout()}}
                >                <span class="text-left">
                       <svg class="h-6 w-6 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Logout
                    </span>
                </a>
                {/* <a class={`flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500 ${props.page=="carorder"?"border-r-4 border-orange-500 bg-gradient-to-r from-white to-orange-100  0":""}`}
                href="/carorders">
                    <span class="text-left">
                        <svg width="20" height="20" fill="currentColor" class="m-auto" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1024 1131q0-64-9-117.5t-29.5-103-60.5-78-97-28.5q-6 4-30 18t-37.5 21.5-35.5 17.5-43 14.5-42 4.5-42-4.5-43-14.5-35.5-17.5-37.5-21.5-30-18q-57 0-97 28.5t-60.5 78-29.5 103-9 117.5 37 106.5 91 42.5h512q54 0 91-42.5t37-106.5zm-157-520q0-94-66.5-160.5t-160.5-66.5-160.5 66.5-66.5 160.5 66.5 160.5 160.5 66.5 160.5-66.5 66.5-160.5zm925 509v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm0-260v-56q0-15-10.5-25.5t-25.5-10.5h-568q-15 0-25.5 10.5t-10.5 25.5v56q0 15 10.5 25.5t25.5 10.5h568q15 0 25.5-10.5t10.5-25.5zm0-252v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23zm256-320v1216q0 66-47 113t-113 47h-352v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-768v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96h-352q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1728q66 0 113 47t47 113z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Car Orders
                    </span>
                </a> */}
                
                {/* <a class="flex items-center justify-start w-full p-4 my-2 font-thin text-gray-500 uppercase transition-colors duration-200  hover:text-orange-500" href="#">
                    <span class="text-left">
                        <svg width="20" fill="currentColor" height="20" class="w-5 h-5" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1088 1256v240q0 16-12 28t-28 12h-240q-16 0-28-12t-12-28v-240q0-16 12-28t28-12h240q16 0 28 12t12 28zm316-600q0 54-15.5 101t-35 76.5-55 59.5-57.5 43.5-61 35.5q-41 23-68.5 65t-27.5 67q0 17-12 32.5t-28 15.5h-240q-15 0-25.5-18.5t-10.5-37.5v-45q0-83 65-156.5t143-108.5q59-27 84-56t25-76q0-42-46.5-74t-107.5-32q-65 0-108 29-35 25-107 115-13 16-31 16-12 0-25-8l-164-125q-13-10-15.5-25t5.5-28q160-266 464-266 80 0 161 31t146 83 106 127.5 41 158.5z">
                            </path>
                        </svg>
                    </span>
                    <span class="mx-4 text-sm font-normal">
                        Leaves
                    </span>
                </a> */}
            </div>
        </nav>
    </div>
    </div>
    
                
    <div class=" lg:invisible visible fixed bottom-0 flex z-40 flex-row w-full ">
    
    
    
    <div class="relative w-full p-2  bg-white shadow-lg rounded-t-xl md:w-60 ">
       
        <div class="grid grid-cols-3 gap-4">
            <div class="flex flex-col items-center"
                    onClick={()=>{navigate("/dashboard")}}
    
            >
                <div class="relative">
                    <a href="" class="relative block">
                    <svg class={`h-8 w-8 ${props.page=="home"?"text-orange-500":"text-gray-500"} `}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />  <polyline points="9 22 9 12 15 12 15 22" /></svg>   
                    {/* <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg> */}
                    
                    
                                          </a>
                   
                </div>
                <span class=" text-xs text-gray-600 ">
                    Home
                </span>
            </div>
            
            <div class="flex flex-col items-center"
                    onClick={()=>{navigate("/realestatedashboard")}}
    
            >
                <div class="relative">
                    <a href="" class="relative block">
                    {/* <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg> */}
                    <svg class={`h-8 w-8 ${props.page=="listings"?"text-orange-500":"text-gray-500"} `}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>
                    
                                          </a>
                   
                </div>
                <span class=" text-xs text-gray-600 ">
                    Listings
                </span>
            </div>
            
            <div class="flex flex-col items-center"
                    onClick={()=>{navigate("/realestateorders")}}
    
            >
                <div class="relative">
                    <a href="" class="relative block">
                    {/* <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg> */}
                    <svg class={`h-8 w-8 ${props.page=="orders"?"text-orange-500":"text-gray-500"} `} width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="12 4 4 8 12 12 20 8 12 4" />  <polyline points="4 12 12 16 20 12" />  <polyline points="4 16 12 20 20 16" /></svg>
                                          </a>
                   
                </div>
                <span class=" text-xs text-gray-600 ">
                    Orders
                </span>
            </div>
            
          
        
            
        </div>
    </div>
    
    </div>
         
         
        </div>



    
    </>
   
   
   
   
   
);


}


export default SideNav