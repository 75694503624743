import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'


import SideNav from './SideNav'

const Dashboard=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [sort,setSort]= useState(false)


  const [cars,setcars]= useState([])
  const [realestates,setRealestates]= useState([])
  const [realestatecount,setRealestatesCount]= useState({})
  const [realestateordercount,setRealestatesOrderCount]= useState({})
  const [carcount,setCarCount]= useState({})
  const [carordercount,setCarOrderCount]= useState({})

  const[orders,setOrders]= useState([])
  const[carOrders,setCarOrders]= useState([])
  const[realestateOrders,setRealestateOrders]= useState([])
  const[services,setServices]= useState([])

  
        


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();


  useEffect(  ()=>{



    fetch('/summary',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

    const propertyCount = result.realestates.reduce((acc, property) => {
        acc[property.realestateType] = (acc[property.realestateType] || 0) + 1;
        return acc;
    }, {});
    const carCount = result.cars.reduce((acc, property) => {
        acc[property.realestateType] = (acc[property.realestateType] || 0) + 1;
        return acc;
    }, {});
    const realestateOrderadd = result.realestateOrders.reduce((acc, property) => {
        acc[property.product.realestateType] = (acc[property.product.realestateType] || 0) + 1;
        return acc;
    }, {});
    const carOrderadd = result.carOrders.reduce((acc, property) => {
        acc[property.product.realestateType] = (acc[property.product.realestateType] || 0) + 1;
        return acc;
    }, {});


// console.log("CNT",realestateOrderCount)

setRealestatesCount(propertyCount)
setCarCount(carCount)
setCarOrderCount(carOrderadd)
setRealestatesOrderCount(realestateOrderadd)

    setcars(result.cars)

setRealestates(result.realestates)
   
      setOrders(result.orders)
      setCarOrders(result.carOrders)
      setRealestateOrders(result.realestateOrders)
      setServices(result.services)
  })
  
  

  },[])


  const SortData = ()=>{

    fetch('/sortStudents',{

      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
        
    }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.patients)
    })


  }


  const SortDataDec = ()=>{

    fetch('/sortStudentsDec',{

      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
        
    }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.patients)
    })


  }

 

  const SortDataName = ()=>{

    fetch('/sortStudentsName',{

      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
        
    }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.patients)
    })


  }


  const SortDataDecName = ()=>{

    fetch('/sortStudentsDecName',{

      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
        
    }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.patients)
    })


  }




  const SortDataDept = ()=>{

    fetch('/sortStudentsDepartment',{

      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
        
    }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.patients)
    })


  }


  const SortDataDecDept = ()=>{

    fetch('/sortStudentsDecDepartment',{

      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
        
    }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.patients)
    })


  }



  const Logout = ()=>{
    localStorage.clear("user")
    history("/login")
}


return(

    <div>


{/* 
<div  className="flex h-screen bg-white">

<div className=" "></div>


<SideNav/>



        <div className="flex-1 flex flex-col overflow-hidden ">

            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">


            <div>


<script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

<div  className="flex h-screen bg-white">

<div className=" "></div>

       <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
 






       <div className="flex-1 flex flex-col overflow-hidden ">

           


           <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
 


              
</main>
</div>

   </div>
 </div>  

</main>
</div>
</div> */}



<main class="relative h-screen overflow-hidden bg-gray-100 ">
    <div class="flex items-start justify-between">
       <SideNav page="home"/>
        <div class="flex flex-col w-full md:space-y-4">
            <header class="z-40 flex items-center justify-between w-full h-16">
                <div class="block ml-6 lg:hidden">
                    <button class="flex items-end p-2 text-gray-500 bg-white rounded-full shadow text-md"
                    onClick={()=>{Logout()}}
                    >
                    <svg class="h-8 w-8 text-gray-400"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>
                    </button>
                </div>
                <div class="relative z-20 flex flex-col justify-end h-full px-3 md:w-full">
                   
                </div>
            </header>
            <div class="h-screen px-4 pb-24 overflow-auto md:px-6">
                <h1 class="text-4xl font-semibold text-gray-800 ">
                    Welcome to Drukings Dashboard
                </h1>
                <h2 class="text-gray-400 text-md">
                    Here&#x27;s what&#x27;s happening with your business.
                </h2>
                {/* <div class="flex flex-col items-center w-full my-6 space-y-4 md:space-x-4 md:space-y-0 md:flex-row">
                    <div class="w-full md:w-6/12">
                        <div class="relative w-full overflow-hidden bg-white shadow-lg ">
                            <a href="#" class="block w-full h-full">
                                <div class="flex items-center justify-between px-4 py-6 space-x-4">
                                    <div class="flex items-center">
                                        <span class="relative p-5 bg-yellow-100 rounded-full">
                                            <svg width="40" fill="currentColor" height="40" class="absolute h-5 text-yellow-500 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                                </path>
                                            </svg>
                                        </span>
                                        <p class="ml-2 text-sm font-semibold text-gray-700 border-b border-gray-200 ">
                                            Level 2 Ambassador
                                        </p>
                                    </div>
                                    <div class="mt-6 text-xl font-bold text-black border-b border-gray-200 md:mt-0 ">
                                        $44,453.39
                                        <span class="text-xs text-gray-400">
                                            /$100K
                                        </span>
                                    </div>
                                </div>
                                <div class="w-full h-3 bg-gray-100">
                                    <div class="w-2/5 h-full text-xs text-center text-white bg-green-400">
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="flex items-center w-full space-x-4 md:w-1/2">
                        <div class="w-1/2">
                            <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                                <p class="text-2xl font-bold text-black ">
                                    12
                                </p>
                                <p class="text-sm text-gray-400">
                                    Active projects
                                </p>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                                <p class="text-2xl font-bold text-black ">
                                    $93.76
                                </p>
                                <p class="text-sm text-gray-400">
                                    Commission in approval
                                </p>
                                <span class="absolute p-4 bg-purple-500 rounded-full top-2 right-4">
                                    <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center space-x-4">
                    <button class="flex items-center px-4 py-2 text-gray-400 border border-gray-300 rounded-r-full rounded-tl-sm rounded-bl-full text-md">
                        <svg width="20" height="20" fill="currentColor" class="mr-2 text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M192 1664h288v-288h-288v288zm352 0h320v-288h-320v288zm-352-352h288v-320h-288v320zm352 0h320v-320h-320v320zm-352-384h288v-288h-288v288zm736 736h320v-288h-320v288zm-384-736h320v-288h-320v288zm768 736h288v-288h-288v288zm-384-352h320v-320h-320v320zm-352-864v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm736 864h288v-320h-288v320zm-384-384h320v-288h-320v288zm384 0h288v-288h-288v288zm32-480v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm384-64v1280q0 52-38 90t-90 38h-1408q-52 0-90-38t-38-90v-1280q0-52 38-90t90-38h128v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h384v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h128q52 0 90 38t38 90z">
                            </path>
                        </svg>
                        Last month
                        <svg width="20" height="20" class="ml-2 text-gray-400" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z">
                            </path>
                        </svg>
                    </button>
                    <span class="text-sm text-gray-400">
                        Compared to oct 1- otc 30, 2020
                    </span>
                </div> */}
                <div class="grid grid-cols-1 gap-4 my-4 md:grid-cols-2 lg:grid-cols-3">
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Realestate Posts
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                    {realestates.length}
                                </p>
                                {/* <span class="flex items-center text-xl font-bold text-green-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    22%
                                </span> */}
                            </div>
                            <div class="">
                                <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                    <p>
                                        Land
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {realestatecount?realestatecount.Land:"0"}
                                        {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            22%
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Building
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {realestatecount?realestatecount.Building:"0"}
                                    {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Flat
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {realestatecount?realestatecount.Flat:"0"}
                                    {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
                                    <p>
                                        Rent
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {realestatecount?realestatecount.Rent:"0"}
{/* 
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            41%
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Cars
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                    {cars.length}
                                </p>
                                <span class="flex items-center text-xl font-bold text-green-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    12%
                                </span>
                            </div>
                            <div class="">
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Bikes
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {carcount?carcount.Bikes:"0"}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            20%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Scooter
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carcount?carcount.Scooter:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Cars
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carcount?carcount.Cars:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    SUV
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carcount?carcount.SUV:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Truck
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carcount?carcount.Truck:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
                                    <p>
                                    Dumper
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carcount?carcount.Dumper:"0"}

                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Survey Order
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                    {services.length}
                                </p>
                                <span class="flex items-center text-xl font-bold text-red-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    2%
                                </span>
                            </div>
                            <div class="">
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Down
                                    </p>
                                    <div class="flex items-end text-xs">
                                        34
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            22%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Up
                                    </p>
                                    <div class="flex items-end text-xs">
                                        13
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
                                    <p>
                                        No developed
                                    </p>
                                    <div class="flex items-end text-xs">
                                        45
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            41%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Realestate Orders
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                    {realestateOrders.length}
                                </p>
                                {/* <span class="flex items-center text-xl font-bold text-red-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    14%
                                </span> */}
                            </div>
                            <div class="">
                                <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                    <p>
                                        Land
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {realestateordercount?realestateordercount.Land:"0"}
                                        {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            22%
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Building
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {realestateordercount?realestateordercount.Building:"0"}
                                    {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                        Flat
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {realestateordercount?realestateordercount.Flat:"0"}
                                    {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span> */}
                                    </div>
                                </div>
                                <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
                                    <p>
                                        Rent
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {realestateordercount?realestateordercount.Rent:"0"}

                                        {/* <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            41%
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Cars Order
                            </p>
                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                    {carOrders.length}
                                </p>
                                <span class="flex items-center text-xl font-bold text-green-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    34%
                                </span>
                            </div>
                            <div class="">
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Bikes
                                    </p>
                                    <div class="flex items-end text-xs">
                                        {carordercount?carordercount.Bikes:"0"}
                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            20%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Scooter
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carordercount?carordercount.Scooter:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Cars
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carordercount?carordercount.Cars:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    SUV
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carordercount?carordercount.SUV:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
                                    <p>
                                    Truck
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carordercount?carordercount.Truck:"0"}
                                    <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            2%
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
                                    <p>
                                    Dumper
                                    </p>
                                    <div class="flex items-end text-xs">
                                    {carordercount?carordercount.Dumper:"0"}

                                        <span class="flex items-center">
                                            <svg width="20" fill="currentColor" height="20" class="h-3 text-red-500 transform rotate-180" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                </path>
                                            </svg>
                                            12%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   */}
                </div>
            </div>
        </div>
    </div>
</main>

    </div>
);


}


export default Dashboard