
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Nav';
import 'react-toastify/dist/ReactToastify.css';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {UserContex} from '../App'
import Footer from './Footer';


const SingleCar = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [data,setData]= useState({})
  const [cart,setCart]= useState([])
  const [url,setUrl] = useState([])  

  const [message,setMessage] = useState("")
  const [generalService,setGeneralService]= useState(false)


  const {productid} =useParams()


  

  useEffect(  ()=>{




    const l = JSON.parse(localStorage.getItem('cart'))

    if(l){
      setCart(l)
    }


    fetch(`/getSingleCar/${productid}`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
    console.log(result.realestate)
      setData(result.realestate)
      setUrl(result.realestate.pic)
      
  })

  },[])


  const addtoCart = (item)=>{

    // console.log("ADD TO CART",item)
    setCart(olditem=>[...olditem, {product:item,qty:1}])
  

  
  
  const json = localStorage.getItem("cart");
    const previousPosts = json ? JSON.parse(json) : [];
    const updatedPosts = [...previousPosts, {product:item,qty:1}];
    // const uniquePosts = Array.from(new Set(updatedPosts))
    const stringifyed = JSON.stringify(updatedPosts);
    localStorage.setItem("cart", stringifyed);
  }
  
  const bookrealestate = ()=>{

    fetch('/bookcar',{
  
      method:"post",
      headers:{
        "Content-Type":"application/json",
  
      },
      body: JSON.stringify({
      name,
      email,
      phone,
      address,
      message,
      product:productid
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        toast.error("Unsuccessful Query")

        console.log("Error")
      }
  
      else{
          toast.success("Sent Successfully")
        //  history.go('/')

    //    window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }
  

    return(


<>
<Header></Header>


<ToastContainer/>


{data &&data.hasOwnProperty("pic") ?
<>
<div class="relative max-w-screen-xl p-4 px-4 mx-auto bg-white dark:bg-gray-800 sm:px-6 lg:px-8 ">
    <div class="relative">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">

            
            



<Carousel showArrows={true}  showThumbs={true}

>
         
         
{url ? url.map(pic=>{
 return(

<div  
>

   <img  src={pic} alt="medicine" class=" " />
  

    </div>



   

 )
}):<></> }         



</Carousel>

                         
            <div class="ml-auto lg:col-start-2 lg:max-w-2xl">
                <p class="text-base font-semibold leading-6 text-blue-500 uppercase">
                    Description
                </p>
                <h4 class="mt-2 text-2xl font-extrabold leading-8 text-gray-900 dark:text-white sm:text-3xl sm:leading-9">
                    {data.realestateType}
                </h4>
                <p class="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
                    {data.description}
                </p>
                <ul class="gap-6 mt-8 md:grid md:grid-cols-2 mb-6">
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                            <svg class="h-8 w-8 text-green-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                {data.address}
                            </span>
                        </div>
                    </li>
                   
                  
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                            <svg class="h-10 w-10 text-green-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
</svg>

                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                Consultation Available
                            </span>
                        </div>
                    </li>
                </ul>





        
         
         <button type="button" class="py-2 mt-4 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        
        // onClick={()=>{addtoCart(data)}}
        onClick={()=>{setGeneralService(true)}}

        
        >
<svg class="h-8 w-8 mr-2 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="12" y1="8" x2="12" y2="16" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>
    Place Order
</button>
    



        
        
{
generalService? 
(
  <>
    <div
      className="justify-cente rounded-lg w-auto border-rounded shadow  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative border-rounded  rounded-lg  my-6 mx-auto max-w-3xl">
        {/*content*/}
       


          <form class="flex w-full max-w-sm space-x-3 content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="mb-6 text-3xl font-light  text-center text-gray-800 dark:text-white">
            Post Query
        </div>
       
       
       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Address</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Address"
            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >

            </input>
          </div>

          <div class="col-span-2">
                        <label class="text-gray-700">
                            <textarea class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"  placeholder="Enter your Details"  rows="3" cols="40"
                              value={message}
                              onChange={(e)=>setMessage(e.target.value)}
                            >
                            </textarea>
                        </label>
                    </div>
         
       
        
              <br/>
                
               
                
                </div>
        </form> 
         

        <div className="flex items-center  justify-end p-6 rounded-b">
            <button
              className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease"  }}
              onClick={() =>setGeneralService(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={()=>{setGeneralService(false);bookrealestate()}}
              >
              Post 
            </button>

            
          </div>





          
          
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)

      :null

}
             
       








            </div>
           
        </div>
    </div>
</div>






<div>

{/* <p class="mt-2 mb-4 text-xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:text-2xl text-center">
            Recommended for you
        </p> */}

<div class="flex flex-col bg-white m-auto p-auto">

<div
  class="flex overflow-x-scroll pb-10  hide-scroll-bar"
>
  <div
    class="flex flex-nowrap  lg:ml-24 md:ml-10 ml-4 "
  >
  





  </div>
</div>
</div>
 
</div>

</>:<>LOADING...</>

}










<Footer></Footer>
</>
  


    );
}

export default SingleCar;