import React, {useContext, useRef, useEffect, useState} from 'react'

// import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'
import Nav from './Nav'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer'

import AOS from "aos"
import "aos/dist/aos.css"

const About=()=>{
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [flightService,setFlightService]= useState(false)
  const [generalService,setGeneralService]= useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [destination,setDestination] = useState("")
  const [address,setAddress] = useState("")
  const [serviceType,setserviceType] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const[url,setUrl]= useState()

  const[progress,setProgress]= useState(false)


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();




useEffect(  ()=>{
  AOS.init();
  AOS.refresh();

},[])

const bookFlight=()=>{

 if(url){  fetch("/addFlight",{
      method:"post",
      headers:{
          "Content-Type":"application/json",

      },
      body: JSON.stringify({
        
       
         name,
         email,
         phone,
         destination,
         date:startDate,
         url
        
      })
      
  }).then(res=>res.json())
  .then(data=> {

    console.log(data.flight)
     
      if(data.error){
        toast.error(data.error)      
      }
      else{

        toast.success("Delivered Successfully")      

        // console.log(data.result)
        
          //  history.push(`/home`)
      }
  })
  .catch(err=>{
      console.log(err)
  })
}

  else{

    toast.error("File Not Uploaded")

  }



   }


   const serviceBook=()=>{


    
    fetch("/addService",{
      method:"post",
      headers:{
          "Content-Type":"application/json",

      },
      body: JSON.stringify({
        
       
        name, phone, email,date:startDate ,address,description,serviceType
        
      })
      
  }).then(res=>res.json())
  .then(data=> {

    console.log(data.service)
     
      if(data.error){
        toast.error(data.error)      
      }
      else{

        toast.success("Order Placed Successfully")      

        // console.log(data.result)
        
          // history.push(`/home`)
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }

   const uploadimg=({target:{files}})=>{

   setProgress(true)
    let data = new FormData()

    for(const file of files){

   data.append('pdf', file)
   data.append('name',file.name)
}
       fetch("/pdf", {
        'Content-Type': 'multipart/form-data',
         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{
       

          setUrl(data.path)
          setProgress(false)


       
     })
     .catch(err=>{
         console.log(err)
     })

}




 
  // const images = [
  //   { url: "/logo.png" },
  //   { url: "/Aer.png" },
  //   { url: "http://www.drukings.com/wp-content/uploads/2021/07/Aeroplane-08-1.png" },
    
  // ];


  const Services = [


    "Electrical","Plumbing","AC Repair","Carpenter","Flight","Netflix","Construction","Architecture and Consultant","CCTV Installation","Realestate","Land","Flat"

  ]


  
  




return(

//   <main class="bg-gray-50 h-screen  relative ">
//     <div class="flex items-start justify-between">
       
//         <div class="flex flex-col w-full ">

            
//             <Nav/>


            
// <ToastContainer/>
          



// <div data-aos="zoom-in" class=" bg-white pt-4 pb-6">

// <h1 class="text-center font-bold text-2xl sm:text-4xl text-black leading-tight mt-4  ">About Us</h1>

//     <img data-aos="flip-left" data-aos-duration="800" src="/under.png" class="mx-auto"></img>

// </div>



         
// <div data-aos="fade-up" class="flex flex-wrap w-full bg-white overflow-auto ">
//     <div class="flex flex-col w-full md:w-1/2">
       
//         <div class="grid h-1/2 place-items-center  ">

//         <div class="flex  content-center mb-4">
 
//         <div class="mt-4">
//                 <p class="text-gray-800 text-lg font-medium mb-2">
//                     Experts Only
//                 </p>
//                 <p class="text-gray-400 text-xs">
//                     Our professionals are well trained and experts of the field.
//                 </p>
              
//             </div>
        
//     </div>
     
      

//                 </div>
//             </div>
//             <div class="md:w-1/2 w-full">
//             <img  src='/about.png' class="   px-4 bg-white text-gray-700  " />

      
      
//              </div>
//         </div>
















//             {/* <Helmet>
//         <title>Services</title>
//         <meta name="description" content="Flight Bookings, Electrician, Plumber, Construction" />
//     </Helmet>
//             */}


           




















//         </div>
//     </div>


  
// <footer class="px-3 py-8 absolute inset-x-0 bottom-0 bg-gray-200 text-2 text-gray-500  transition-colors duration-200">
//     <div class="flex flex-col">
//         <div class="md:hidden mt-7 mx-auto w-11 h-px rounded-full">
//         </div>
//         <div class="mt-4 md:mt-0 flex flex-col md:flex-row">
            
//         <img  src='/logo.png' class=" pl-8  w-28 h-28 items-center justify-center " />

            
//             <nav class="flex-1 flex flex-col items-center justify-center md:items-end md:border-r border-gray-100 md:pr-5">
//                 <a aria-current="page" href="#" class="hover:text-gray-700 ">
//                     About Us
//                 </a>
//                 <a aria-current="page" href="#" class="hover:text-gray-700 ">
//                     Contact Us
//                 </a>
               
//             </nav>
//             <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full">
//             </div>
//             <div class="mt-4 md:mt-0 flex-1 flex items-center justify-center md:border-r border-gray-100">
               
//                 <a class="ml-4 hover:text-primary-gray-20" href="https://www.facebook.com/drukings">
                   
//                     <svg class="h-8 w-8 text-xl hover:text-gray-800  transition-colors duration-200"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" /></svg>
//                 </a>
//                 <a class="ml-4 hover:text-primary-gray-20" href="https://www.instagram.com/drukings/">
//                 <svg class="h-8 w-8 text-xl hover:text-gray-800  transition-colors duration-200"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
//                 </a>
//             </div>
//             <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full ">
//             </div>
//             <div class="mt-7 md:mt-0 flex-1 flex flex-col items-center justify-center md:items-start md:pl-5">
//                 <span class="">
//                     © {new Date().getFullYear()}
//                 </span>
//                 <span class="mt-7 md:mt-1">
//                     Drukings.com
                    
//                 </span>
//             </div>
//         </div>
//     </div>
// </footer>


    
    
    

// </main>

<main class="bg-gray-50 h-screen  relative ">
<div class="flex items-start justify-between">
   
    <div class="flex flex-col w-full ">

        
        <Nav/>


      
<ToastContainer/>
      



<div data-aos="zoom-in" class=" bg-white pt-4 pb-6">

<h1 class="text-center font-bold text-2xl sm:text-4xl text-black leading-tight mt-4  ">About Us</h1>

{/* <img data-aos="flip-left" data-aos-duration="800" src='/ul.png'  class="mx-auto"></img> */}



</div>


<div data-aos="fade-up" class="flex flex-wrap w-full overflow-auto bg-white">
<div class="flex flex-col w-full md:w-1/2">
   
    <div class="grid h-1/2 place-items-center  ">

    <div class="flex  content-center mb-4">
    <div class="mt-4">
            <p class="text-gray-800 text-lg  font-small p-4 mb-2">
            Welcome to Drukings, Bhutan’s premier online marketplace for land transactions. Our mission is to provide a secure and transparent platform that simplifies the buying and selling process. We envision becoming the most trusted and innovative real estate marketplace in Bhutan, connecting individuals, families, and businesses with ease. At the heart of our operations are our core values: integrity, innovation, customer focus, excellence, and sustainability. We are dedicated to building trust, enhancing user experiences, and promoting responsible land use in every transaction.

            
                 </p>
            <p class="text-gray-500 text-s p-4">
            Our dedicated team of professionals combines extensive local knowledge with a personalized approach, helping you navigate the complexities of real estate transactions with ease. Whether you're looking to purchase, sell, or rent, Saamling Real Estate is here to guide you every step of the way, making your real estate journey smooth and successful.






            </p>
          
        </div>
    
</div>
    {/* <div class="flex  content-center mb-4">
    <img  src='/bill.png' class="  flex-1 appearance-none w-28 h-28 py-2 px-4 bg-white text-gray-700 " />

    <div class="mt-4">
            <p class="text-gray-800 text-lg font-medium mb-2">
                Efficient and Transparent Price
            </p>
            <p class="text-gray-400 text-xs">
            Pay after you are saticefied with our services. 0 hidden charge
            </p>
          
        </div>
    
</div> */}
    {/* <div class="flex  content-center mb-4">
    <img  src='/experience.png' class="  flex-1 appearance-none w-28 h-28 py-2 px-4 bg-white text-gray-700 " />

    <div class="mt-4">
            <p class="text-gray-800 text-lg font-medium mb-2">
                Exquiped and Experience
            </p>
            <p class="text-gray-400 text-xs">
            We come equiped and can handle any unforeseen siuations.
            </p>
          
        </div>
    
</div>
  */}

            </div>
        </div>
        <div class="md:w-1/2 ">
        <img  src='/logo.png' class="  bg-white text-gray-700  " />

  
  
         </div>
    </div>
















        {/* <Helmet>
    <title>Services</title>
    <meta name="description" content="Flight Bookings, Electrician, Plumber, Construction" />
</Helmet>
        */}


       




















    </div>
</div>



<Footer></Footer>





</main>


);


}


export default About