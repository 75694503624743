import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";



import Nav from './Nav'
import SideNav from './SideNav'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SingleRealestate from './SingleRealestate';

const RealestateDashboard=()=>{
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [sort,setSort]= useState(false)


  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[map,setMap]= useState("")
  const[embedmap,setEmbedMap]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[id,setId]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[rateEdit,setRateEdit]= useState()
    

  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [status, setStatus] = useState("")
  const [description, setDescription] = useState("")
  const [destination,setDestination] = useState("")
  const [address,setAddress] = useState("")
  const [serviceType,setserviceType] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const[url,setUrl]= useState([])
  const[progress,setProgress]= useState(false)


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();


  useEffect(  ()=>{



    fetch('/viewrealestatedash',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.realestates)
  })
  
  

  },[])


  const postStockUpdate = (id,stat)=>{

    fetch('/updaterealestateStatus',{
  
      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")
  
      },
      body: JSON.stringify({
      id:id,
      status:stat
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
  
      else{
        //  history('/realestatedashboard')

       window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }
  
  const postEmbedMap = (id)=>{

    fetch('/updaterealestateembed',{
  
      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")
  
      },
      body: JSON.stringify({
      id:id,
      embedmap:embedmap
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
  
      else{
        //  history('/realestatedashboard')

       window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }
  

  const deleteNetflixSuscription = (postId)=>{

    fetch(`/deleterealestate`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        },
        body: JSON.stringify({
            id:postId
            })

    }).then(res=>res.json())
    .then(result=>{
        history('/dashboard')

    }).catch(err=>{
        console.log(err)
    })
}



const fetchPost=(query)=>{


  setSearch(query)

  fetch('/search-realestatedashboard',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      query
    })

  }).then(res=> res.json())
    .then(results=>{
      
      setData(results.realestate)
    })


}
const getSingle=(query)=>{


  // setSearch(query)

  fetch(`/getSingleRealestate/${query}`,{

    method:"get",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    }

  }).then(res=> res.json())
    .then(results=>{
      
      // setData(results.realestate)
      setId(results.realestate._id)
      setName(results.realestate.name)
      setEmail(results.realestate.email)
      setPhone(results.realestate.phone)
      setDescription(results.realestate.description)
      setAddress(results.realestate.address)
      setUrl(results.realestate.pic)
      setMap(results.realestate.map)
      setStatus(results.realestate.status)
  
      setserviceType(results.realestate.realestateType)

    })


}



const serviceBook=()=>{

  fetch("/addrealestate",{
    method:"post",
    headers:{
        "Content-Type":"application/json",

    },
    body: JSON.stringify({
      
     
      name, phone, email,date:startDate ,address,description,map,realestateType:serviceType, pic:url
      
    })
    
}).then(res=>res.json())
.then(data=> {

  console.log(data.service)
   
    if(data.error){
      toast.error(data.error)      
    }
    else{

      toast.success("Delivered Successfully")      

      // console.log(data.result)
      window.location.reload(false);

        //  history(`/buyrealestate`)
    }
})
.catch(err=>{
    console.log(err)
})

 }


 const postUpdate = ()=>{

  fetch('/updaterealestate',{

    method:"put",
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer " +localStorage.getItem("jwt")

    },
    body: JSON.stringify({
    id:id,
    name, phone, email,date:startDate ,address,description,map,realestateType:serviceType, pic:url,status

    
    })
    
  }).then(res=>res.json())
  .then(data=>{
    if(data.error){
      console.log("Error")
    }

    else{
      //  history('/realestatedashboard')

     window.location.reload(false);
    }
  })
  .catch(err=>{
    console.log(err)
  })

}


 const uploadimg=({target:{files}})=>{

  setProgress(true)

  let data = new FormData()

  for(const file of files){

 data.append('uploadimage', file)
 data.append('name',file.name)
}
     fetch("/uploadimage", {

       method:"post",
       body: data
   })
   .then(res=>res.json())
   .then(data=>{

        console.log(data.url)

        setUrl(oldArray => [...oldArray, data.url])

       // setUrl(item=>[...item, {data.url}])


        console.log(url)
        setProgress(false)


     
   })
   .catch(err=>{
       console.log(err)
   })

}





return(

//     <div>



// <div  className="flex h-screen bg-white">

// <div className=" "></div>


// <SideNav/>



//         <div className="flex-1 flex flex-col overflow-hidden ">

          
//            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">


             
//            <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
//   <input type="text" name="search" id="search" placeholder="Search" 
//          className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
//          value= {search} onChange={(e)=>fetchPost(e.target.value)}
//          />
//   <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
//     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
//          viewBox="0 0 24 24" className="w-6 h-6">
//       <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
//     </svg>
//   </button>
// </div>

                   
              
//              <h1 class="text-center p-4" >Not Verified</h1>
 

//            <div class="grid grid-cols-1 md:grid-cols-4 gap-4 ml-4">
                   
                   
                   
                   
                   

//                    {data ? data.map(items=>{

//                        return(
       
//                         <>
//                         {items.status=="Not Verified"? 
                        
                                                       
//           <div class="shadow-lg rounded-2xl  bg-white ">
//             <div class=" ">
              
//             <Carousel autoPlay showThumbs={false}>

//               {items.pic.map((pics,index)=>{

//                 return(
//                   <div>
//                  <img src={pics} alt="adidas" class="w-80 h-80" />

//                   </div>
//                 )

//               })}

//               </Carousel>
                
                
//             </div>
//     <div class="bg-white  p-4 rounded-lg">
//         <p class="text-gray-600 text-xl font-bold ">
//             {items.realestateType}
//         </p>
//          <div class="flex items-center justify-between ">
//             <p class="text-gray-400">
//                 {items.description}
//             </p>
            
//         </div>
//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
            
//             <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
// </svg>
// </span>
//            <span className="text-sm text-gray-500 ">{items.address}</span>        </p>
        
        
//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
//                   <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
// </svg>
// </span>
//            <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


//                     <a  href={`tel:${items.phone}`}>

//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
            
//         <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

// </span>
//            <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



//            <a href={`mailto:${items.email}`}>
//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
//         <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

// </span>
//            <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     

        
        
       
//            <h6>Status: {items.status}</h6>

//            <button class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 px-4  rounded"
                                  
//                                   onClick={()=>{if(window.confirm('Are you sure it is Delivered?')) postStockUpdate(items._id)}}
//                                   >
//                                     Verified
//                                       </button>


//        <div class="flex flex-row">


      

      
//        <div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
                  
//                   onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteNetflixSuscription(items._id)}}
            
//                   >
//                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
//                   ></path>  
//                   </svg></div>
           

//  </div>



//     </div>
// </div>:null

// }
                
                        
                        
                       
//                             </>
                        
                        

                            
                            
                            
//                             )


//                    }):null }

                   
                        
                               
//                                    </div>
//              <h1 class="text-center p-4" >Verified</h1>
 

//            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ml-4">
                   
                   
                   
                   
                   

//                    {data ? data.map(items=>{

//                        return(

//                         <>



//                         {items.status=="Verified"?
                        
                        
//                         <>
                        
                        
                                                       
//           <div class="shadow-lg rounded-2xl  bg-white ">
//             <div class=" ">
              
//             <Carousel autoPlay showThumbs={false}>

//               {items.pic.map((pics,index)=>{

//                 return(
//                   <div>
//                  <img src={pics} alt="adidas" class="w-80 h-80" />

//                   </div>
//                 )

//               })}

//               </Carousel>
                
                
//             </div>
//     <div class="bg-white m-2  rounded-lg">
//         <p class="text-gray-600 text-xl font-bold ">
//             {items.realestateType}
//         </p>
//          <div class="flex items-center justify-between ">
//             <p class="text-gray-400">
//                 {items.description}
//             </p>
            
//         </div>
//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
            
//             <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
// </svg>
// </span>
//            <span className="text-sm text-gray-500 ">{items.address}</span>        </p>
        
        
//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
//                   <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
// </svg>
// </span>
//            <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


//                     <a  href={`tel:${items.phone}`}>

//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
            
//         <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

// </span>
//            <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



//            <a href={`mailto:${items.email}`}>
//         <p class=" flex flex-row text-gray-50 text-xs">
//         <span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
//         <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

// </span>
//            <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     

        
        
       
//            <h6>Status: {items.status}</h6>

//        <div class="flex flex-row">


      

      
//        <div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
                  
//                   onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteNetflixSuscription(items._id)}}
            
//                   >
//                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
//                   ></path>  
//                   </svg></div>
           

//  </div>



//     </div>
// </div>


                
                        
                        
//                         {/* <div class="shadow-lg rounded-2xl p-4 bg-white  cursor-pointer" 
                   
//                             >
                               
//                                 <div class="flex flex-col justify-start">
//                                 <p class="text-gray-800  text-xl  mt-4 text-center">

//                                         <a  className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
//            <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
            
//             <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
// </svg>
// </span>
//            <span className="text-sm text-gray-500 font-medium">{items.name}</span>
//          </a>
//                                         <a href={`tel:${items.phone}`} className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
//            <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
            
//             <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
            
            
//             </span>
//            <span className="text-sm text-gray-500 font-medium">{items.phone}</span>
//          </a>
//                                         <a href={`mailto:${items.email}`} className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
//            <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"> 
//            <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
           
//            </span>
//            <span className="text-sm text-gray-500 font-medium">{items.email}</span>
//          </a>
//                                         <a  className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
//            <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
// </svg>
// </span>
//            <span className="text-sm text-gray-500 font-medium">{items.address}</span>
//          </a>


//                                         <h6>Status: {items.status}</h6>



                                        
//                                         </p>
                                  
  
//                                 </div>
//                             </div> 
                            
//                              */}
//                             </>
                        
                        
                        
//                         :null}


                        
                        
                  
                              
                    

//                             </>  )


//                    }):null }

                   
                        
                               
//                                    </div>
                   
                
                   
              
          
                   
              
// </main>
// </div>

//    </div>
//  </div>  

<main class="relative h-screen overflow-auto bg-gray-100  rounded-2xl">
    <div class="flex items-start justify-between">
       


      <SideNav page="listings"/>


        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
            
<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64  bg-white   ">
    
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>        </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data ? data.length:0}
            </p>
            <p class="text-gray-700 text-sm">
                Total Listings 
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-orange-500 hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Stocks +
        </button>
    </div>
</div>


{addStock?<>

      <div className="md:flex w-full">
        {/* <div className="hidden md:block w-1/2 bg-gray-50 py-10 px-10">

            <img  src='./logo.png'></img>
        </div> */}
        <div className="w-full py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Add Property</h1>
          </div>
          <div>
          <div className="flex -mx-3">
              
            </div>
            
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4 ">

            <div className="py-1">

          <span className=" text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
</div>
            <div className="py-1">

          <span className="text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
            </div>




<div className="py-1">


          <span className="text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
</div>
            <div className="py-1">

          <span className="text-sm text-gray-600">Address</span>
           
        
            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >
            <option>Select Location</option>


            <option>Bumthang</option>
            <option>Chhukha</option>
            <option>Dagana</option>
            <option>Gelephu</option>
            <option>Gasa</option>
            <option>Haa</option>
            <option>Lhuntse</option>
            <option>Mongar</option>
            <option>Paro</option>
            <option>Pemagatshel</option>
            <option>Punakha</option>
            <option>Samdrup Jongkhar</option>
            <option>Samtse</option>
            <option>Sarpang</option>
            <option>Thimphu</option>
            <option>Trashigang</option>
            <option>Trashiyangtse</option>
            <option>Trongsa</option>
            <option>Tsirang</option>
            <option>Wangdue Phodrang</option>
            <option>Zhemgang</option>
           
            </select>
            </div>
            <div className="py-1">
            <span className="text-sm text-gray-600">Property Type</span>

            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



              value={serviceType}
              onChange={(e)=>setserviceType(e.target.value)}
            >
            <option>Select Type </option>


            <option>Land</option>
            <option>Building</option>
            <option value="Flat">Flat for Sale</option>
            <option value="Rent">Flat for Rent</option>
            </select>
            
            </div>

</div>


<div class="grid  grid-cols-3 gap-4 ">


            <div className="py-1">
         
          <span className="text-sm text-gray-600">Map Location</span>
            <input  type="text" className="space-y-8 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            
            value={map}
            onChange={(e)=>setMap(e.target.value)}
            >

            </input>
</div>
            <div className="py-1">
         
          <span className="text-sm text-gray-600">Description</span>
            <textarea  type="text" className="space-y-8 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            >

            </textarea>
</div>

<div className="py-1">

            <div className="col-span-6 sm:col-span-4 mt-6">
            <label className="flex flex-col items-center px-4 py-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">   </span>
                {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadimg}
                />
                
            </label>
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
        
        <div class="grid grid-cols-4 grid-row-2 grid-flow-row auto-cols-min ">

        { url.length>0? url.map((pics,index)=>{

return(
  <div className=''>
  <img src={pics} alt="img" 

  class= "p-1 w-24 h-24" />
<svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"
onClick={()=>{


setUrl([
...url.slice(0, index),
...url.slice(index + 1, url.length)
]);

}}
>  <circle cx="12" cy="12" r="10" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>
  </div>
)

}):<></> }
       
  </div>


        {/* {url.length>0? url.map(item=>{
          return(
            <img className=" pt-4 pb-2  h-24 w-24 flex items-center " src={item} ></img>
          )
        }) :null
        } */}
            
        
        
        </div>
        </div>   

        </div>   
           
        <div class="grid  grid-cols-2 gap-4 ">

            <div class="m-2.5">
                                <span class="block w-full rounded-md shadow-sm">
                                    <button type="button" class="py-2 px-4  bg-orange-300 hover:bg-orange-400 focus:bg-orange-400 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                  onClick={()=>{serviceBook()}}

                                    >
                                        Post
                                    </button>
                                    
                                </span>
                            </div>
            <div class="m-2.5">
                                <span class="block w-full rounded-md shadow-sm">
                                    <button type="button" class="py-2 px-4  bg-red-400 hover:bg-red-500 focus:bg-orange-400 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                  onClick={()=>{setAddStock(false)}}

                                    >
                                        Cancle
                                    </button>
                                    
                                </span>
                            </div>
</div>
        
              
    </div>
</div>

        
          </div>


</>:<></>}
{editStock?<>

      <div className="md:flex w-full">
        {/* <div className="hidden md:block w-1/2 bg-gray-50 py-10 px-10">

            <img  src='./logo.png'></img>
        </div> */}
        <div className="w-full py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Edit Property</h1>
          </div>
          <div>
          <div className="flex -mx-3">
              
            </div>
            
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4 ">

            <div className="py-1">

          <span className=" text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
</div>
            <div className="py-1">

          <span className="text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
            </div>




<div className="py-1">


          <span className="text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
</div>
            <div className="py-1">

          <span className="text-sm text-gray-600">Address</span>
           
        
            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



            value={address}
            onChange={(e)=>setAddress(e.target.value)}
            >
            <option>Select Location</option>


            <option>Bumthang</option>
            <option>Chhukha</option>
            <option>Dagana</option>
            <option>Gelephu</option>
            <option>Gasa</option>
            <option>Haa</option>
            <option>Lhuntse</option>
            <option>Mongar</option>
            <option>Paro</option>
            <option>Pemagatshel</option>
            <option>Punakha</option>
            <option>Samdrup Jongkhar</option>
            <option>Samtse</option>
            <option>Sarpang</option>
            <option>Thimphu</option>
            <option>Trashigang</option>
            <option>Trashiyangtse</option>
            <option>Trongsa</option>
            <option>Tsirang</option>
            <option>Wangdue Phodrang</option>
            <option>Zhemgang</option>
           
            </select>
            </div>
            <div className="py-1">
            <span className="text-sm text-gray-600">Property Type</span>

            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



              value={serviceType}
              onChange={(e)=>setserviceType(e.target.value)}
            >
            <option>Select Type </option>


            <option>Land</option>
            <option>Building</option>
            <option value="Flat">Flat for Sale</option>
            <option value="Rent">Flat for Rent</option>
            </select>
            
            </div>

</div>


<div class="grid  grid-cols-3 gap-4 ">
<div className="py-1">
            <span className="text-sm text-gray-600">Status</span>

            <select className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 



              value={status}
              onChange={(e)=>setStatus(e.target.value)}
            >
            <option>Select Type </option>


            <option>Not Verified</option>
            <option>Verified</option>
            <option >Sold</option>
          
            </select>
            
            </div>

            <div className="py-1">
         
          <span className="text-sm text-gray-600">Map Location</span>
            <input  type="text" className="space-y-8 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            
            value={map}
            onChange={(e)=>setMap(e.target.value)}
            >

            </input>
</div>
            <div className="py-1">
         
          <span className="text-sm text-gray-600">Description</span>
            <textarea  type="text" className="space-y-8 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
            >

            </textarea>
</div>

<div className="py-1">

            <div className="col-span-6 sm:col-span-4 mt-6">
            <label className="flex flex-col items-center px-4 py-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">   </span>
                {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadimg}
                />
                
            </label>
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
        
        <div class="grid grid-cols-4 grid-row-2 grid-flow-row auto-cols-min ">

    { url.length>0? url.map((pics,index)=>{

      return(
        <div className=''>
        <img src={pics} alt="img" 

        class= "p-1 w-24 h-24" />
<svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"
onClick={()=>{

    
    setUrl([
      ...url.slice(0, index),
      ...url.slice(index + 1, url.length)
    ]);

}}
>  <circle cx="12" cy="12" r="10" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>
        </div>
      )

    }):<></> }
       
       
  </div>


        {/* {url.length>0? url.map(item=>{
          return(
            <img className=" pt-4 pb-2  h-24 w-24 flex items-center " src={item} ></img>
          )
        }) :null
        } */}
            
        
        
        </div>
        </div>   

        </div>   
           
        <div class="grid  grid-cols-2 gap-4 ">

            <div class="m-2.5">
                                <span class="block w-full rounded-md shadow-sm">
                                    <button type="button" class="py-2 px-4  bg-orange-300 hover:bg-orange-400 focus:bg-orange-400 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                  onClick={()=>{postUpdate()}}

                                    >
                                        Update
                                    </button>
                                    
                                </span>
                            </div>
            <div class="m-2.5">
                                <span class="block w-full rounded-md shadow-sm">
                                    <button type="button" class="py-2 px-4  bg-red-400 hover:bg-red-500 focus:bg-orange-400 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                  onClick={()=>{setEditStock(false)}}

                                    >
                                        Cancle
                                    </button>
                                    
                                </span>
                            </div>
</div>
        
              
    </div>
</div>

        
          </div>


</>:<></>}
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                 

                <h1 class="text-center p-4" >Not Verified</h1>
 

 <div class="grid grid-cols-1 md:grid-cols-3 gap-4 ml-4">
         
         
         
         
         

         {data ? data.map(items=>{

             return(

              <>
              {items.status=="Not Verified"? 
              
                                             
<div class="shadow-lg rounded-2xl  bg-white ">
  <div class=" ">
    
  <Carousel autoPlay showThumbs={false}>

    {items.pic.map((pics,index)=>{

      return(
        <div>
          
       <img src={pics} alt="adidas" class="w-80 h-80" />

        </div>
      )

    })}

    </Carousel>
      
      
  </div>
<div class="bg-white  p-4 rounded-lg">
<p class="text-gray-600 text-xl font-bold ">
  {items.realestateType}
</p>
<div class="flex items-center justify-between ">
  <p class="text-gray-400">
      {items.description}
  </p>
  
</div>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.address}</span>        </p>


<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
        <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


          <a  href={`tel:${items.phone}`}>

<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



 <a href={`mailto:${items.email}`}>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     




 <h6>Status: {items.status}</h6>

 <a href={items.map}> {items.map}</a>


{!items.embedmap?
 <div class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
 <div class=" relative ">
     <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="embeded map"
     value={embedmap}
     onChange={(e)=>setEmbedMap(e.target.value)}
     />
     </div>
     <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
     onClick={()=>{postEmbedMap(items._id)}}
     >
         Add
     </button>
 </div>

:<></>}

 
 {/* <button class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 px-4  rounded"
                        
                        onClick={()=>{if(window.confirm('Are you sure it is Verified?')) postStockUpdate(items._id,"Verified")}}
                        >
                          Verified
                            </button> */}
 
 <button class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 px-4  rounded"
                        
                        onClick={()=>{ getSingle(items._id);setEditStock(true)}}
                        >
                          Verify
                            </button>


<div class="flex flex-row">





<div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
        
        onClick={()=>{if(window.confirm('Are you sure you want to DELETE?')) deleteNetflixSuscription(items._id)}}
  
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
  
        ></path>  
        </svg></div>
 

</div>



</div>
</div>:null

}
      
              
              
             
                  </>
              
              

                  
                  
                  
                  )


         }):null }

         
              
                     
                         </div>
   <h1 class="text-center p-4" >Verified</h1>


 <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ml-4 mb-8">
         
         
         
         
         

         {data ? data.map(items=>{

             return(

              <>



              {items.status=="Verified"?
              
              
              <>
              
              
                                             
<div class="shadow-lg rounded-2xl  bg-white ">
  <div class=" ">
    
  <Carousel autoPlay showThumbs={false}>

    {items.pic.map((pics,index)=>{

      return(
        <div>
       <img src={pics} alt="adidas" class="h-56" />

        </div>
      )

    })}

    </Carousel>
      
      
  </div>
<div class="bg-white m-2  rounded-lg relative">
<p class="text-gray-600 text-xl font-bold ">
  {items.realestateType}
  
</p>
<div style={{cursor:"pointer"}} className=" absolute top-0 right-0"
        
        onClick={()=>{getSingle(items._id);setEditStock(true)}}
        // onClick={()=>{if(window.confirm('Are you sure you want to make it SOLD?')) postStockUpdate(items._id,"Sold")}}
  
        >
     <svg class="h-8 w-8 text-orange-500"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg>
        
        </div>
 

<div class="flex items-center justify-between ">
  <p class="text-gray-400">
      {items.description}
  </p>
  
</div>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.address}</span>        </p>


<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
        <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


          <a  href={`tel:${items.phone}`}>

<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



 <a href={`mailto:${items.email}`}>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     




 <h6>Status: {items.status}</h6>
 <a href={items.map}> {items.map}</a>


{!items.embedmap?
 <div class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
 <div class=" relative ">
     <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="embeded map"
     value={embedmap}
     onChange={(e)=>setEmbedMap(e.target.value)}
     />
     </div>
     <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
     onClick={()=>{postEmbedMap(items._id)}}
     >
         Add
     </button>
 </div>

:<></>}

<button class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 px-4  rounded"
                        
                        onClick={()=>{if(window.confirm('Are you sure you want to make it SOLD?')) postStockUpdate(items._id,"Sold")}}
                        >
                          Sold
                            </button>


<div class="flex flex-row">



<div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
        
        onClick={()=>{if(window.confirm('Are you sure want to DELETE?')) deleteNetflixSuscription(items._id)}}
        // onClick={()=>{if(window.confirm('Are you sure you want to make it SOLD?')) postStockUpdate(items._id,"Sold")}}
  
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
  
        ></path>  
        </svg></div>
 

</div>



</div>
</div>


      
              
              
              {/* <div class="shadow-lg rounded-2xl p-4 bg-white  cursor-pointer" 
         
                  >
                     
                      <div class="flex flex-col justify-start">
                      <p class="text-gray-800  text-xl  mt-4 text-center">

                              <a  className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
  
  <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 font-medium">{items.name}</span>
</a>
                              <a href={`tel:${items.phone}`} className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
  
  
  </span>
 <span className="text-sm text-gray-500 font-medium">{items.phone}</span>
</a>
                              <a href={`mailto:${items.email}`} className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"> 
 <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
 
 </span>
 <span className="text-sm text-gray-500 font-medium">{items.email}</span>
</a>
                              <a  className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 font-medium">{items.address}</span>
</a>


                              <h6>Status: {items.status}</h6>



                              
                              </p>
                        

                      </div>
                  </div> 
                  
                   */}
                  </>
              
              
              
              :null}


              
              
        
                    
          

                  </>  )


         }):null }

         
              
                     
                         </div>
         
      

   <h1 class="text-center p-4" >Sold</h1>


 <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ml-4 mb-8">
         
         
         
         
         

         {data ? data.map(items=>{

             return(

              <>



              {items.status=="Sold"?
              
              
              <>
              
              
                                             
<div class="shadow-lg rounded-2xl  bg-white ">
  <div class=" ">
    
  <Carousel autoPlay showThumbs={false}>

    {items.pic.map((pics,index)=>{

      return(
        <div>
       <img src={pics} alt="adidas" class="h-56" />

        </div>
      )

    })}

    </Carousel>
      
      
  </div>
<div class="bg-white m-2  rounded-lg relative">
<p class="text-gray-600 text-xl font-bold ">
  {items.realestateType}
  
</p>
<div style={{cursor:"pointer"}} className=" absolute top-0 right-0"
        
        onClick={()=>{getSingle(items._id);setEditStock(true)}}
        // onClick={()=>{if(window.confirm('Are you sure you want to make it SOLD?')) postStockUpdate(items._id,"Sold")}}
  
        >
     <svg class="h-8 w-8 text-orange-500"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg>
        
        </div>
 

<div class="flex items-center justify-between ">
  <p class="text-gray-400">
      {items.description}
  </p>
  
</div>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.address}</span>        </p>


<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
        <svg class="h-5 w-5 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 ">{items.name}</span>        </p>


          <a  href={`tel:${items.phone}`}>

<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
  
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.phone}</span>        </p></a>



 <a href={`mailto:${items.email}`}>
<p class=" flex flex-row text-gray-50 text-xs">
<span className="inline-flex text-gray-400 items-center justify-center text-lg text-gray-400">
<svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>

</span>
 <span className="text-sm text-gray-500 ">{items.email}</span>        </p>            </a>     




 <h6>Status: {items.status}</h6>
 <a href={items.map}> {items.map}</a>


{!items.embedmap?
 <div class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
 <div class=" relative ">
     <input type="text" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="embeded map"
     value={embedmap}
     onChange={(e)=>setEmbedMap(e.target.value)}
     />
     </div>
     <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
     onClick={()=>{postEmbedMap(items._id)}}
     >
         Add
     </button>
 </div>

:<></>}

<button class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 px-4  rounded"
                        
                        onClick={()=>{if(window.confirm('Are you sure you want to make it SOLD?')) postStockUpdate(items._id,"Sold")}}
                        >
                          Sold
                            </button>


<div class="flex flex-row">



<div style={{cursor:"pointer"}} className=" text-red-600 mt-2"
        
        onClick={()=>{if(window.confirm('Are you sure want to DELETE?')) deleteNetflixSuscription(items._id)}}
        // onClick={()=>{if(window.confirm('Are you sure you want to make it SOLD?')) postStockUpdate(items._id,"Sold")}}
  
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
  
        ></path>  
        </svg></div>
 

</div>



</div>
</div>


      
              
              
              {/* <div class="shadow-lg rounded-2xl p-4 bg-white  cursor-pointer" 
         
                  >
                     
                      <div class="flex flex-col justify-start">
                      <p class="text-gray-800  text-xl  mt-4 text-center">

                              <a  className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
  
  <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 font-medium">{items.name}</span>
</a>
                              <a href={`tel:${items.phone}`} className="flex flex-row items-center text-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400">
  
  <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
  
  
  </span>
 <span className="text-sm text-gray-500 font-medium">{items.phone}</span>
</a>
                              <a href={`mailto:${items.email}`} className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"> 
 <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
 
 </span>
 <span className="text-sm text-gray-500 font-medium">{items.email}</span>
</a>
                              <a  className="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 text-center hover:text-gray-800">
 <span className="inline-flex text-gray-400 items-center justify-center h-12 w-12 text-lg text-gray-400"><svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
</span>
 <span className="text-sm text-gray-500 font-medium">{items.address}</span>
</a>


                              <h6>Status: {items.status}</h6>



                              
                              </p>
                        

                      </div>
                  </div> 
                  
                   */}
                  </>
              
              
              
              :null}


              
              
        
                    
          

                  </>  )


         }):null }

         
              
                     
                         </div>
         
      


                 
                </div>
            </div>
        </div>
    </main>



);


}


export default RealestateDashboard