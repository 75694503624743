import React, {useContext, useRef, useEffect, useState} from 'react'

// import {Helmet} from 'react-helmet'
import {Link, } from 'react-router-dom'
import {UserContex} from '../App'
import logo from './logo.png'
import Nav from './Nav'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer'
import AOS from "aos"
import "aos/dist/aos.css"

const Contact=()=>{
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [flightService,setFlightService]= useState(false)
  const [generalService,setGeneralService]= useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [destination,setDestination] = useState("")
  const [address,setAddress] = useState("")
  const [serviceType,setserviceType] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const[url,setUrl]= useState()

  const[progress,setProgress]= useState(false)


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();




useEffect(  ()=>{
  AOS.init();
  AOS.refresh();

},[])





 
  // const images = [
  //   { url: "/logo.png" },
  //   { url: "/Aer.png" },
  //   { url: "http://www.drukings.com/wp-content/uploads/2021/07/Aeroplane-08-1.png" },
    
  // ];



  
  




return(


<main class="bg-gray-50  h-screen  relative ">
<div class="flex items-start justify-between">
   
    <div class="flex flex-col w-full ">

        
        <Nav/>


      
<ToastContainer/>
      



<div data-aos="zoom-in" class=" bg-white pt-4 pb-6">

<h1 class="text-center font-bold text-2xl sm:text-4xl text-black leading-tight mt-4  ">Contact Us</h1>

{/* <img data-aos="flip-left" data-aos-duration="800" src='/ul.png'  class="mx-auto"></img> */}



</div>


<div data-aos="fade-up" class="flex flex-wrap w-full bg-white overflow-auto ">
<div class="flex flex-col w-full md:w-1/2">
   
    <div class="grid h-1/2 place-items-center  ">

   
<div class="flex w-full max-w-sm space-x-3">
    <div class="w-full max-w-2xl px-5 py-10 m-auto  bg-white rounded-lg shadow ">
        <div class="mb-6 text-3xl font-light text-center text-gray-800 ">
            Contact us !
        </div>
        <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">
            <div class="col-span-2 lg:col-span-1">
                <div class=" relative ">
                    <input type="text" id="contact-form-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" placeholder="Name"/>
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1">
                    <div class=" relative ">
                        <input type="text" id="contact-form-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" placeholder="email"/>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <label class="text-gray-700" for="name">
                            <textarea class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" id="comment" placeholder="Enter your comment" name="comment" rows="5" cols="40">
                            </textarea>
                        </label>
                    </div>
                    <div class="col-span-2 text-right">
                        <button type="submit" class="py-2 px-4  bg-blue-500 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>



 

            </div>
        </div>
        <div className="hidden md:block w-1/2  py-10 px-10">

<img className='h-80'  src='./logo.png'></img>
</div>
    </div>
















        {/* <Helmet>
    <title>Services</title>
    <meta name="description" content="Flight Bookings, Electrician, Plumber, Construction" />
</Helmet>
        */}


       




















    </div>
</div>



<Footer/>






</main>


);


}


export default Contact